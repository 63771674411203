.contentCard {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin: 20px 10px;
}
.tablet .contentCard {
    margin: 20px;
}

.contentCard h3 {
    margin: 0 0 10px;
}
