.hubs {
    padding: 15px 15px 100px;
}
.tablet .hubs {
    padding: 30px 30px 100px;
    max-width: 70vw;
    width: 70vw;
    margin: 0 auto;
}

.hubs-header {
    border-bottom: 3px solid #fff;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

.hubs-title {
    font-weight: 600;
    font-size: 22px;
    display: flex;
    align-items: center;
}
.mobileMd .hubs-title {
    font-size: 30px;
}

.hubs-tagIcon {
    width: 30px;
    height: 1.25em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/tag-light.svg");
    transform: rotate(-45deg);
    margin: 3px 10px 0;
}
.mobileMd .hubs-tagIcon {
    width: 35px;
}

.hubs-headerButtons {
    display: flex;
    align-items: center;
}

.hubs-headerButton {
    background: none;
    border: 2px solid #fff;
    color: #fff;
    margin-left: 3px;
    border-radius: 5px;
    font-size: 12px;
    width: 55px;
    cursor: pointer;
    height: 30px;
    font-weight: 600;
}
.hubs-headerButton:focus,
.hubs-headerButton:hover {
    background-color: #525f6d;
}
.mobileMd .hubs-headerButton {
    width: 65px;
}

.hubs-noHubsMessage {
    padding: 10px 0;
}
