.register {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.tabletSm .register {
    width: 66%;
}

.desktop .register {
    width: 33%;
}

.register-title {
    font-size: 17px;
    margin-bottom: 10px;
}

.register-helpText {
    font-size: 11px;
    max-width: 70%;
    margin-bottom: 10px;
    text-align: center;
    color: #8e7747;
}

.register-input {
    width: 100%;
}

.register-maskedInput {
    -webkit-text-security: disc;
    -moz-text-security: disc;
    text-security: disc;
}

.register-preferences {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin: 10px 0;
}

.register-preferenceDescription {
    margin-left: 15px;
}

.register-preferenceSubtext {
    font-size: .75em;
}

.register-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    width: 100%;
}

.register-submitButton {
    align-self: flex-end;
    background-color: #4b8ec8;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 13px;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
}

.register-submitButton:hover,
.register-submitButton:focus {
    background-color: #56aaee;
}
