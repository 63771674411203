.settingsModal-fieldInfo {
    padding: 8px 16px;
    border-bottom: 1px solid #aaa;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    height: 45px;
    justify-content: space-between;
}
.tablet .settingsModal-fieldInfo {
    height: 32px;
}

.settingsModal-fieldInfo:hover,
.settingsModal-fieldInfo:focus {
    background-color: #ebebeb;
}

.searchModal-fieldInfoLeft {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
}
.tablet .searchModal-fieldInfoLeft {
    flex-direction: row;
}

.settingsModal-fieldLabel {
    font-weight: 600;
}
.tablet .settingsModal-fieldLabel {
    width: 150px;
}

.settingsModal-fieldValue {
    flex: 1;
}

.settingsModal-editButton {
    color: #1a6aff;
}

.settingsModal-editField {
    padding: 8px 16px;
    border-bottom: 1px solid #aaa;
    display: flex;
    align-items: center;
    height: 45px;
}
.tablet .settingsModal-editField {
    height: 32px;
}

.settingsModal-editField label {
    font-weight: 600;
    width: 150px;
}

.settingsModal-editFieldInner {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.tablet .settingsModal-editFieldInner {
    flex-direction: row;
    align-items: center;
}

.settingsModal-inputField {
    flex: 1;
}

.settingsModal-input {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    padding: 0;
}
.tablet .settingsModal-input {
    padding: 5px 0;
}

.settingsModal-saveButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    margin-top: 8px;
}

.tablet .settingsModal-saveButtonContainer {
    margin-top: 0;
}

.settingsModal-saveButtonContainer button {
    cursor: pointer;
}

.settingsModal-submitButton {
    margin-left: 8px;
    background-color: #4b8ec8;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 13px;
    border: none;
    padding: 7px 15px;
}

.settingsModal-submitButton:hover,
.settingsModal-submitButton:focus {
    background-color: #56aaee;
}

.settingsModal-cancelButton {
    background-color: #ececec;
    border: none;
    font-size: 13px;
    padding: 7px 15px;
    margin-left: 8px;
}

.settingsModal-cancelButton:hover,
.settingsModal-cancelButton:focus {
    background-color: #dbdbdb;
}

.settingsModal-buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.settingsModal-buttonContainer button {
    background-color: #ececec;
    cursor: pointer;
    flex: 1;
    padding: 15px;
    border: none;
    margin: 0;
}
.settingsModal-buttonContainer button:focus,
.settingsModal-buttonContainer button:hover {
    background-color: #dbdbdb;
}
