.cookbooks {
    margin-bottom: 150px;
}

.cookbooks-cookbookActions {
    margin: 20px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.tablet .cookbooks-cookbookActions {
    flex-direction: row;
}

.cookbooks-createCookbookButton {
    border-radius: 5px;
    background-color: #3FEEA5;
    border: 2px solid #3FEEA5;
    padding: 5px 10px;
    font: inherit;
    font-weight: 700;
    color: #2d333c;
    cursor: pointer;
    margin: 0;
}
.cookbooks-createCookbookButton:hover {
    background-color: #42ffb3;
}
