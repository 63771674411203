.cookbookPreview {
    display: flex;
    flex-direction: column;
    margin: 20px;
}
.desktop .cookbookPreview {
    margin: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #b9b9b9;
    flex-direction: row;
}

.cookbookPreview.cookbookPreview--noRecipes {
    border: none;
    margin: 0 0 20px 0;
    padding-bottom: 0;
}
.tablet .cookbookPreview.cookbookPreview--noRecipes {
    margin: 0 20px 20px 0;
}
.tablet .cookbookPreview.cookbookPreview--noRecipes:last-child {
    margin-right: 0;
}

.cookbookPreview-cookbookButton {
    display: flex;
    flex-direction: column;
    border: none;
    background-color: #fff;
    font: inherit;
    color: inherit;
    text-align: left;
    cursor: pointer;
    width: 100%;
    padding: 10px;
    margin: 0;
    border-radius: 10px;
}
.tablet .cookbookPreview-cookbookButton {
    width: 300px;
    min-width: 300px;
    flex-direction: row;
    align-items: center;
}
.cookbookPreview-cookbookButton:hover {
    background-color: #fff5e1;
}

.contentCard.cookbookPreview-cookbookContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    margin: 0;
    padding: 20px 10px 3px;
    width: calc(100% - 20px);
    height: calc(100% - 30px);
}

.cookbookPreview-cookbookIconWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.cookbookPreview-cookbookIcon {
    height: 125px;
    width: 125px;
}

.cookbookPreview-cookbookText {
    margin-top: 20px;
}

.cookbookPreview-cookbookTitle {
    font-weight: 700;
    font-size: 1.15em;
}

.cookbookPreview-cookbookSubtext {
    font-size: 0.9em;
    color: #414141;
}

.cookbookPreview-deletionWarning {
    font-weight: 700;
    color: #ff3c3a;
    margin-top: 5px;
}

.desktop .cookbookPreview-line {
    border-radius: 3px;
    align-self: stretch;
    background-color: #818181;
    width: 3px;
    min-width: 3px;
    margin: 0 20px;
}

.cookbookPreview-recipePreviews {
    display: none;
}
.tablet .cookbookPreview-recipePreviews {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.recipePreview.cookbookPreview-recipePreview {
    margin-bottom: 0;
    display: none;
}

.desktop .cookbookPreview-recipePreview--1,
.desktopXl .cookbookPreview-recipePreview--2,
.desktopXXl .cookbookPreview-recipePreview--3,
.desktop4k .cookbookPreview-recipePreview--4 {
    display: unset;
}

.cookbookPreview-noRecipesMessage {
    display: none;
}
.desktop .cookbookPreview-noRecipesMessage {
    padding: 10px;
    color: #404040;
    border: 2px solid #7e7e7e;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    align-self: center;
    margin: 0 auto;
}
.desktopXl .cookbookPreview-noRecipesMessage {
    padding: 10px 50px;
}

.cookbookPreview-buttons {
    display: none;
}
.desktop .cookbookPreview-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
}

.tablet .cookbookPreview-addRecipeButton {
    border-radius: 5px;
    background-color: #3FEEA5;
    border: 2px solid #3FEEA5;
    font: inherit;
    font-weight: 700;
    color: #2d333c;
    cursor: pointer;
    font-size: 0.95em;
    width: unset;
    padding: 10px 15px;
    margin: 0 0 10px 0;
}
.cookbookPreview-addRecipeButton:hover {
    background-color: #42ffb3;
}

.cookbookPreview-viewMoreButton {
    border-radius: 5px;
    background-color: #4386ee;
    border: 2px solid #4386ee;
    font: inherit;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    font-size: 0.95em;
    width: unset;
    padding: 10px 15px;
    margin: 0;
}
.cookbookPreview-viewMoreButton:hover {
    border-color: #4793ff;
    background-color: #4793ff;
}
