
.createHubModal-mainContent {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.createHubModal-mainContent label {
    font-weight: 600;
    font-size: 18px;
}

.createHubModal-label {
    margin-left: 0;
    font-size: 1em;
}

.createHubModal .fieldWrapper-error {
    margin-left: 0;
}

.createHubModal-description {
    margin: 5px 0 14px;
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid #a9a9a9;
    resize: vertical;
    min-height: 1em;
    height: 3em;
}

.createHubModal-defaultRole {
    padding: 10px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}

.createHubModal-defaultRoleText {
    font-size: .7em;
    margin-left: 10px;
}
.tablet .createHubModal-defaultRoleText {
    font-size: .8em;
}

.createHubModal-defaultRoleSubText {
    font-size: .85em;
    font-weight: normal;
}
.tablet .createHubModal-defaultRoleSubText {
    font-size: .75em;
}

.tablet .createHubModal .createHubModal-checkbox {
    min-height: 15px;
    min-width: 15px;
    width: 15px;
    height: 15px;
    padding: 1px;
}

.tablet .createHubModal .createHubModal-checkmark {
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
}

.createHubModal-buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.createHubModal-buttonContainer button {
    background-color: #ececec;
    cursor: pointer;
    flex: 1;
    padding: 15px;
    margin: 0;
}
.createHubModal-buttonContainer button:focus,
.createHubModal-buttonContainer button:hover {
    background-color: #dbdbdb;
}

.createHubModal-cancelButton {
    border-bottom-left-radius: 10px;
    border: 1px solid #aaaaaa;
    border-right: none;
}

.createHubModal-submitButton {
    border-bottom-right-radius: 10px;
    border: 1px solid #aaaaaa;
}