.tooltip {
    position: relative;
}

.tooltip-bubble {
    visibility: hidden;
    max-width: 110px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s;
}
.tablet .tooltip-bubble {
    max-width: none;
    padding: 5px;
}

.tooltip .tooltip-bubble::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
}

.tooltip:hover .tooltip-bubble {
    visibility: visible;
    opacity: 1;
}