.gift {
    border-bottom: 1px solid #c5c5c5;
}

.gift-primary {
    display: flex;
    align-items: stretch;
}

.gift--claimedByOther .gift-label {
    text-decoration: line-through;
}

.gifts-completeGifts .gift:last-child,
.gifts-incompleteGifts .gift:last-child {
    border-bottom: none;
}

.gift-mainContent {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 10px;
    flex-grow: 1;
    flex-direction: column;
}

.tablet .gift-mainContent {
    flex-direction: row;
}

.gift-leftSection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    width: 100%;
}
.tablet .gift-leftSection {
    width: 50%;
}

.gift-checkbox {
    margin: 0 10px;
    align-self: center;
}

.gift-priorityAndTag {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.tablet .gift-priorityAndTag {
    flex-direction: row;
}

.gift-priority {
    min-width: 25px;
    text-align: center;
    align-self: center;
}

.gift-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gift-rightSection {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}
.tablet .gift-rightSection {
    justify-content: center;
    width: 50%;
}

.gift-price {
    margin-top: 10px;
}

.tablet .gift-price {
    margin: 2px 0;
    text-align: right;
}

.gift-link {
    text-decoration: none;
}
.gift-link:focus,
.gift-link:hover {
    text-decoration: underline;
}

.gift-description {
    font-size: .8em;
    padding-right: 1em;
    white-space: pre-line;
}

.gift-descriptionToggle {
    background: none;
    border: none;
    color: #afafaf;
    padding: 0;
    cursor: pointer;
    font-size: .85em;
    border-bottom: 1px solid #ffffff;
}

.gift-descriptionToggle:focus,
.gift-descriptionToggle:hover {
    border-bottom: 1px solid #d5d5d5;
    outline: none;
}

.gift-date,
.gift-ownedBy {
    font-size: .75em;
    color: #4f4f4f;
}

.gift-deletedText {
    color: #F03A47;
}

.gift-deletedStatus {
    font-weight: 600;
}

.gift-labelPrimary {
    display: flex;
    align-items: center;
}

.gift-label--deleted {
    margin-right: 4px;
}

.gift-tagIcon {
    height: 22px;
    width: 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    transform: rotate(-45deg);
}
.tablet .gift-tagIcon {
    margin: 0 10px;
}

.gift-darkTagIcon {
    background-image: url("../../../../../images/icons/tag-grey.svg");
}

.gift-tagIconGreen {
    background-image: url("../../../../../images/icons/tag-green.svg");
}

.gift-tagIconBlue {
    background-image: url("../../../../../images/icons/tag-blue.svg");
}

.gift-tagIconYellow {
    background-image: url("../../../../../images/icons/tag-yellow.svg");
}

.gift-tagToolTip {
    font-size: .6em;
    display: flex;
    align-items: center;
    align-self: center;
}

.gift-status {
    font-size: .85em;
    display: flex;
    margin-top: 10px;
    color: #000;
    flex-direction: column;
    align-items: flex-start;
}
.tablet .gift-status {
    align-items: flex-end;
    margin: 2px 0;
}

.gift-collaboratorButtons {
    display: flex;
    flex-wrap: wrap;
}

.gift-collaboratorButtons button {
    background-color: #fff;
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 2px 10px 2px 0;
    font-size: 1em;
}
.tablet .gift-collaboratorButtons button {
    padding: 3px 7px;
    margin: 2px 0 2px 10px;
    font-size: .85em;
}
.gift-collaboratorButtons button:focus,
.gift-collaboratorButtons button:hover {
    color: #fff;
    outline: none;
}

.gift-blueButton {
    border: 1px solid #0073ff;
    color: #0073ff;
}
.gift-blueButton:focus,
.gift-blueButton:hover {
    background-color: #0073ff;
}

.gift-redButton {
    border: 1px solid #d63334;
    color: #d63334;
}
.gift-redButton:focus,
.gift-redButton:hover {
    background-color: #d63334;
}

.gift-yellowButton {
    border: 1px solid #cb8e24;
    color: #cb8e24;
}
.gift-yellowButton:focus,
.gift-yellowButton:hover {
    background-color: #eaa924;
    border-color: #eaa924;
}

.gift-greenButton {
    border: 1px solid #2da854;
    color: #2da854;
}
.gift-greenButton:focus,
.gift-greenButton:hover {
    background-color: #2da854;
}

.gift-collaboratorInfoText {
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.gift-collaboratorInfoText--red {
    color: #d63334;
}

.gift-contributorsToolTip {
    text-decoration: underline;
    color: #4f84ff;
}
.gift-contributorsToolTip .tooltip-bubble {
    width: 200px;
    left: -100px
}
.gift-contributorsToolTip .tooltip-bubble::after {
    left: 50%;
}

.gift-contributors {
    border: 2px solid #2ecc71;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.gift-acceptedContributors {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
}
.tablet .gift-acceptedContributors {
    flex-direction: row;
    align-items: center;
}

.gift-acceptedContributorsTitle {
    margin-right: 7px;
    display: flex;
    align-items: center;
}

.gift-contributorsFinalizeText {
    margin-top: 10px;
    font-size: .85em;
}

.gift-finalizeContributorsButton {
    background: none;
    border: none;
    color: #4f84ff;
    text-decoration: underline;
    padding: 0;
    margin: 0 0 0 5px;
    cursor: pointer;
}

.gift-acceptedContributor {
    background-color: #32bb6b;
    color: #fff;
    font-size: .75em;
    display: flex;
    align-items: center;
    margin: 2px;
}

.gift-acceptedContributorLabel {
    padding: 5px;
}

.gift-removeAcceptedContributorButton {
    margin: 0;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}
.tablet .gift-removeAcceptedContributorButton {
    padding: 6px;
}
.gift-removeAcceptedContributorButton:focus,
.gift-removeAcceptedContributorButton:hover {
    background-color: #30a051;
}

.gift-closeIcon {
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../../images/icons/close-white.svg");
}

.gift-dropdown:focus,
.gift-dropdown:hover {
    background-color: #f1f1f1;
}

.gift-contextMenu {
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../../images/icons/3_dots.svg");
}

.gift-contextMenuOption {
    width: 100px;
    background: none;
    border: none;
    padding: 7px;
    border-bottom: 1px solid #c5c5c5;
    cursor: pointer;
}
.gift-contextMenuOption:last-child {
    border-bottom: none;
}

.gift-contextMenuOption:focus,
.gift-contextMenuOption:hover {
    background-color: #f1f1f1;
}
