.unclaimModal {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.unclaimModal-deletionMemoLabel {
    margin: 16px;
    font-weight: 600;
}


.unclaimModal-buttonContainer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #bfbfbf;
}

.unclaimModal-cancelButton,
.unclaimModal-confirmButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 20px;
    font-size: 16px;
    flex: 1;
    margin: 0;
}
.unclaimModal-cancelButton:focus,
.unclaimModal-cancelButton:hover,
.unclaimModal-confirmButton:focus,
.unclaimModal-confirmButton:hover {
    background-color: #eeeeee;
}
.unclaimModal-confirmButton {
    color: #d63334;
    border-bottom: 1px solid #dfdfdf;
}
