.giftRectificationModal {

}

.giftRectificationModal-mainBody {
    padding: 15px;
}

.giftRectificationModal-instructions {
    margin-bottom: 20px;
}

.giftRectificationModal-listField {
    margin-bottom: 20px;
}

.giftRectificationModal-listName {
    font-weight: 700;
    font-size: 1.1em;
    margin-bottom: 5px;
}

.giftRectificationModal-giftField {
    border-top: 1px solid #9f9f9f;
}
.giftRectificationModal-giftField:last-child {
    border-bottom: 1px solid #9f9f9f;
}

.giftRectificationModal-giftField--active {
    background-color: #ffedb3;
}

.giftRectificationModal-giftHeaderButton {
    display: flex;
    justify-content: space-between;
    background: none;
    border: none;
    margin: 0;
    width: 100%;
    font-size: 1em;
    padding: 10px;
}

.giftRectificationModal-giftHeaderButton--clickable {
    cursor: pointer;
}
.giftRectificationModal-giftHeaderButton--clickable:focus,
.giftRectificationModal-giftHeaderButton--clickable:hover {
    background-color: #e7e7e7;
}

.giftRectificationModal-status--green {
    color: #008645;
}
.giftRectificationModal-status--yellow {
    color: #c39516;
}
.giftRectificationModal-status--red {
    color: #ff1b00;
}

.giftRectificationModal-responseField {
    display: flex;
    align-items: flex-start;
    padding: 0 10px 10px 20px;
    flex-direction: column;
}
.tablet .giftRectificationModal-responseField {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.giftRectificationModal-responseQuestion {
    margin-bottom: 7px;
}
.tablet .giftRectificationModal-responseQuestion {
    margin-bottom: 0;
}

.giftRectificationModal-responseRadio {
    border: 1px solid #000;
    border-radius: 20px;
    width: 100%;
    display: flex;
}
.tablet .giftRectificationModal-responseRadio {
    width: auto;
}

.giftRectificationModal-responseRadio button {
    background: none;
    border: none;
    border-right: 1px solid #000;
    cursor: pointer;
    padding: 5px 10px;
    overflow: hidden;
    margin: 0;
    flex: 1;
    min-width: 65px;
}
.giftRectificationModal-responseRadio button:first-child {
    border-radius: 20px 0 0 20px;
}
.giftRectificationModal-responseRadio button:last-child {
    border-radius: 0 20px 20px 0;
    border-right: none;
}
.giftRectificationModal-responseRadio button:hover,
.giftRectificationModal-responseRadio button:focus {
    background-color: #d7d7d7;
}

.giftRectificationModal-responseRadio button.giftRectificationModal-responseRadioButton--active:nth-child(1) {
    background-color: #05b98f;
    color: #fff;
}
.giftRectificationModal-responseRadio button.giftRectificationModal-responseRadioButton--active:nth-child(2) {
    background-color: #595959;
    color: #fff;
}
.giftRectificationModal-responseRadio button.giftRectificationModal-responseRadioButton--active:nth-child(3) {
    background-color: #ff3533;
    color: #fff;
}

.giftRectificationModal-responseConfirmation {
    padding: 0 10px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
}

.giftRectificationModal-resolutionButton {
    cursor: pointer;
    background-color: #0081dd;
    color: #fff;
    border: none;
    padding: 7px 15px;
    font-weight: 700;
    margin-left: 10px;
}

.giftRectificationModal-buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.giftRectificationModal-buttonContainer button {
    cursor: pointer;
    flex: 1;
    padding: 15px;
    margin: 0;
}

.giftRectificationModal-cancelButton {
    background-color: #ececec;
    border-bottom-left-radius: 10px;
    border: 1px solid #aaaaaa;
    border-right: none;
}
.giftRectificationModal-cancelButton:focus,
.giftRectificationModal-cancelButton:hover {
    background-color: #dbdbdb;
}
.giftRectificationModal-cancelButton:disabled {
    cursor: unset;
    background-color: #ffffff;
    border-color: #aaaaaa;
    color: #bdbdbd;
}

.giftRectificationModal-submitButton {
    border-bottom-right-radius: 10px;
    border: 1px solid #05b385;
    background-color: #05b385;
    color: #fff;
}
.giftRectificationModal-submitButton:focus,
.giftRectificationModal-submitButton:hover {
    background-color: #05956d;
    border-color: #05956d;
}
.giftRectificationModal-submitButton:disabled {
    cursor: unset;
    background-color: #ffffff;
    border-color: #aaaaaa;
    color: #bdbdbd;
}