.topNav {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    color: #000;
}

.topNav-title {
    color: #F03A47;
    font-weight: 600;
    font-size: 30px;
    font-family: Zian;
    flex: 2;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tablet .topNav-title {
    letter-spacing: 3px;
}

.topNav-titleText:first-child {
    margin-right: 10px;
}

.tablet .topNav-titleText:first-child {
    margin-right: 0;
}

.mobileLg .topNav-title {
    padding: 10px;
}

.tablet .topNav-title {
    font-size: 40px;
}

@font-face {
    font-family: 'Zian';
    src: url('../../../../util/zian.ttf')  format('truetype');
}

.topNav-leftActions {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 5px;
}
.mobileLg .topNav-leftActions {
    padding: 10px;
}

.topNav-icons {
    position: relative;
    display: inline-block;
    margin-right: 10px;
}
.tablet .topNav-icons {
    margin: 0 15px 0 12px;
}

.topNav-giftIcon {
    height: 40px;
    width: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.tablet .topNav-giftIcon {
    height: 50px;
    width: 50px;
}

.topNav-tagIcon {
    height: 10px;
    width: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 53%;
    left: 46%;
    z-index: 100;
}
.tablet .topNav-tagIcon {
    height: 12px;
    width: 12px;
}

.topNav-rightActions {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
}

.topNav-usersName {
    display: none;
    padding: 10px;
}

.desktop .topNav-usersName {
    display: block;
}

.topNav-hamburgerButton {
    background: none;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 3px;
    padding: 3px 5px;
    margin-right: 3px;
    outline: none;
    position: relative;
}
.mobileLg .topNav-hamburgerButton {
    margin-right: 10px;
}

.topNav-hamburgerButton:hover,
.topNav-hamburgerButton:focus {
    border: 1px solid #d7d7d7;
}

.topNav-hamburgerIcon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/hamburger.svg");
    width: 20px;
    height: 20px;
}

.topNav-alertDot {
    background-color: #9A031E;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: -1px;
    right: -1px;
}

.topNav-menuButton {
    border: 1px solid #fff;
    border-radius: 20px;
}
.topNav-menuButton:hover,
.topNav-menuButton:focus {
    border: 1px solid #d7d7d7;
    outline: none;
}

.topNav-menuOption {
    padding: 7px 0;
    width: 100%;
    text-align: center;
}

.topNav-menuOption:focus,
.topNav-menuOption:hover {
    background-color: #f1f1f1;
}
