.deletionModal {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.deletionModal-alertIcon {
    height: 35px;
    width: 35px;
    min-height: 35px;
    min-width: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/warning.svg");
    margin-right: 16px;
}

.deletionModal-targetedGiftMessage {
    padding: 16px 16px 0;
    font-size: .9em;
}

.deletionModal-markReceivedMessage {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: #ffe39f;
    padding: 5px 10px;
    border-radius: 5px;
}

.deletionModal-deletionMemoLabel {
    margin: 16px 16px 8px;
    font-weight: 600;
}

.deletionModal-deletionMemo {
    margin: 0 16px 16px;
}

.deletionModal-buttonContainer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #bfbfbf;
}

.deletionModal-cancelButton,
.deletionModal-confirmButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 20px;
    font-size: 16px;
    flex: 1;
}
.deletionModal-cancelButton:focus,
.deletionModal-cancelButton:hover,
.deletionModal-confirmButton:focus,
.deletionModal-confirmButton:hover {
    background-color: #eeeeee;
}
.deletionModal-confirmButton {
    color: #d63334;
    border-bottom: 1px solid #dfdfdf;
}
