.instructionFields {
    background-color: #eaeaea;
    border-radius: 10px;
    margin-bottom: 8px;
    border: 2px solid #fff;
}
.instructionFields:last-child {
    margin-bottom: 0;
}

.instructionFields-droppable {
    border: 2px solid #70a2ff;
}

.instructionFields-instruction {
    display: flex;
    align-items: center;
    position: relative;
}
.instructionFields-droppable .instructionFields-instruction {
    visibility: hidden;
}

.instructionFields-grabIconContainer {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 0 5px;
    cursor: grab;
}

.instructionFields-grabIcon {
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
}

.instructionFields-content {
    padding: 7px 7px 7px 0;
    display: flex;
    align-items: center;
    flex: 1;
}
.tablet .instructionFields-content {
    padding: 5px 5px 5px 0;
}

.instructionFields-number {
    margin-right: 10px;
}

.instructionFields-descriptionAndButtons {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.tablet .instructionFields-descriptionAndButtons {
    flex-direction: row;
}

.instructionFields-descriptionLabelAndField {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.instructionFields-descriptionLabel .fieldLabel-errorText {
    margin-bottom: 3px;
}

.instructionFields-descriptionTextArea {
    flex: 1;
    border-radius: 5px;
    font: inherit;
    font-size: .8em;
    border: 1px solid #c5c5c5;
    padding: 2px 7px;
    min-height: 96px;
    resize: vertical;
    align-self: stretch;
}
.tablet .instructionFields-descriptionTextArea {
    min-height: 48px;
    margin-right: 10px;
}

.instructionFields-selectedIngredients {
    font-size: 0.7em;
}

.instructionFields-selectedIngredients span {
    font-weight: 500;
}

.instructionFields-photoAndButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.tablet .instructionFields-photoAndButtons {
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
}

.photoPreview-image.instructionFields-photoPreview {
    width: 100px;
    max-width: 100%;
    margin: 15px 13px 7px 7px;
}
.tablet .photoPreview-image.instructionFields-photoPreview {
    width: 100px;
    margin: 7px 13px 7px 7px;
}
.photoPreview--selected .photoPreview-image.instructionFields-photoPreview {
     width: unset;
     max-width: 80vw;
     max-height: 90vh;
 }

.instructionFields-photo .photoPreview-closeButton {
    top: 4px;
    right: 4px;
}

.instructionFields-buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 5px;
    width: 50%;
}
.tablet .instructionFields-buttonContainer {
    margin: 0 10px;
    width: 100%;
    min-width: 110px;
}

.instructionFields-buttonContainer.instructionFields-buttonContainer--stretch {
    width: 100%;
}

.instructionFields-infoText {
    font-size: 0.7em;
    margin-bottom: 5px;
    text-align: center;
}

.instructionFields-buttonContainer button {
    border-radius: 5px;
    border: none;
    padding: 5px 7px;
    font: inherit;
    font-size: 0.7em;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 7px;
}
.tablet .instructionFields-buttonContainer button {
    padding: 3px 7px;
    width: unset;
    margin-bottom: 5px;
}

.instructionFields-finishAddingIngredientsButton,
.instructionFields-addPhotoButton {
    background-color: #3FEEA5;
    color: #2d333c;
}
.instructionFields-finishAddingIngredientsButton:hover,
.instructionFields-addPhotoButton:hover {
    background-color: #42ffb3;
}

.instructionFields-addIngredientsButton {
    background-color: #4386ee;
    color: #fff;
}
.instructionFields-addIngredientsButton:hover {
    background-color: #4493fc;
}

.instructionFields-deleteButton {
    background-color: #ee5759;
    color: #2d333c;
}
.tablet button.instructionFields-deleteButton {
    margin-bottom: 0;
}
.instructionFields-deleteButton:hover {
    background-color: #ff5c5e;
}

.instructionFields-deleteNag {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    background-color: rgba(216, 216, 216, 0.8);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tablet .instructionFields-deleteNag {
    flex-direction: row;
}

.instructionFields-deleteNagText {
    background-color: #ffffff;
    padding: 5px 10px;
    border-radius: 10px;
}

.instructionFields-deleteNagButtonContainer {
    display: flex;
    margin-top: 20px;
    align-self: stretch;
    justify-content: space-between;
}
.tablet .instructionFields-deleteNagButtonContainer {
    margin-top: 0;
    margin-left: 20px;
    align-self: center;
    justify-content: flex-end;
    flex: 1;
}

.instructionFields-yesButton {
    border-radius: 5px;
    background-color: #ee5759;
    border: none;
    padding: 5px 15px;
    font: inherit;
    font-weight: 700;
    color: #2d333c;
    cursor: pointer;
}
.tablet .instructionFields-yesButton {
    margin-right: 40px;
}

.instructionFields-noButton {
    border-radius: 5px;
    background-color: #3f3f3f;
    border: none;
    padding: 5px 15px;
    font: inherit;
    font-weight: 700;
    color: #dbdbdb;
    cursor: pointer;
}

.instructionFields-photoFileInput {
    display: none;
}
