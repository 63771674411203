.checkbox {
    border: 2px solid #343434;
    border-radius: 3px;
    background: none;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkbox:disabled {
    background-color: #cbcbcb;
}

.checkbox-checkmark {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../images/icons/checkmark.svg");
    width: 14px;
    height: 14px;
}