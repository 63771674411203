.joinModal-mainContent {
    padding: 10px;
}

.joinModal label {
    font-weight: 600;
}

.joinModal-searchInputWrapper {
    position: relative;
}

.joinModal .joinModal-searchInput {
    margin: 0 0 10px 0;
    font-size: 1em;
}

.joinModal-searchInputCloseButton {
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-62%);
    transform: translateY(-62%);
    padding: 6px;
    cursor: pointer;
}
.joinModal-searchInputCloseButton:hover,
.joinModal-searchInputCloseButton:focus {
    background-color: #ececec;
}

.joinModal-closeBlackIcon {
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/close-grey.svg");
}

.joinModal-hubButton {
    padding: 10px;
    border: none;
    border-bottom: 1px solid #e6e6e6;
    background: none;
    margin: 0;
    width: 100%;
    cursor: pointer;
    text-align: left;
    font-size: .85em;
    display: flex;
    justify-content: space-between;
}
.joinModal-hubButton:last-child {
    border-bottom: none;
}
.joinModal-hubButton:hover,
.joinModal-hubButton:focus {
    background-color: #fff4d2;
}
.joinModal-hubButton:disabled {
    cursor: unset;
    background-color: #FFF;
}

.joinModal-buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.joinModal-cancelButton {
    background-color: #ececec;
    cursor: pointer;
    flex: 1;
    padding: 15px;
    margin: 0;
    border-bottom-left-radius: 10px;
    border: 1px solid #aaaaaa;
    border-right: none;
}
.joinModal-cancelButton:focus,
.joinModal-cancelButton:hover {
    background-color: #dbdbdb;
}