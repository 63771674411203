.photoPreview {
    display: inline-block;
}

.photoPreview--selected {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0,0,0,0.29);
}

.photoPreview-buttonContainer {
    display: inline-block;
    position: relative;
}
.photoPreview--selected .photoPreview-buttonContainer {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.photoPreview-button {
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
}
.photoPreview--selected .photoPreview-button {
    cursor: default;
}

.photoPreview-image {
    width: 100px;
    max-width: 100%;
    border: 1px solid #000;
    object-fit: contain;
    border-radius: 5px;
}
.tablet .photoPreview-image {
    width: 200px;
}
.photoPreview--selected .photoPreview-image {
    width: unset;
    max-width: 80vw;
    max-height: 90vh;
}

.photoPreview-closeButton {
    background-color: #7e7e7e;
    padding: 5px;
    margin: 0;
    border: none;
    cursor: pointer;
    top: -7px;
    right: -7px;
    position: absolute;
    border-radius: 50%;
}

.photoPreview-closeIcon {
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/close-white.svg");
}
