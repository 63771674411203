.messagesView {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 60vh;
    min-height: 60vh;
}

.messagesView-scroll {
    padding-right: 10px;
    height: 50vh;
    min-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 1 auto;
}
.messagesView-scroll > * {
    flex-shrink: 0;
}
/* width */
.messagesView-scroll::-webkit-scrollbar {
    width: 10px;
}
/* Track */
.messagesView-scroll::-webkit-scrollbar-track {
    background: #1d2029;
}
/* Handle */
.messagesView-scroll::-webkit-scrollbar-thumb {
    background: #006ec6;
}
/* Handle on hover */
.messagesView-scroll::-webkit-scrollbar-thumb:hover {
    background: #009dff;
}

.messagesView-showMoreButton {
    width: 100%;
    padding: 12px 5px;
    font-size: 1em;
    color: #006ec6;
    background-color: #2b303a;
    border: 2px solid #006ec6;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
}
.tablet .messagesView-showMoreButton {
    font-size: .95em;
    padding: 7px;
}
.messagesView-showMoreButton:hover,
.messagesView-showMoreButton:focus {
    background-color: #006ec6;
    color: #2b303a;
}

.messagesView-noMessagesHeader {
    font-size: 22px;
}

.messagesView-noMessagesSubtext {
    font-size: .8em;
    margin-top: 10px;
}

.messagesView-date {
    color: #d6d6d6;
    margin-top: 15px;
    align-self: center;
    padding: 3px 10px;
    text-align: center;
    font-size: .75em;
}
.messagesView-date:first-of-type {
    margin-top: 0;
}

.messagesView-form {
    margin-top: 15px;
    border-radius: 10px;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    justify-content: center;
    background-color: #fff;
}

.messagesView-newMessageField {
    flex-grow: 1;
}

.messagesView .messagesView-newMessage {
    background-color: #fff;
    margin: 0;
    padding: 10px 0 10px 10px;
    font-size: 1em;
    min-height: 20px;
}
.tablet .messagesView-newMessage {
    padding: 5px 0 5px 10px;
}
.messagesView .messagesView-newMessage:focus {
    border-bottom-color: #fff;
}

.messagesView-submitButton {
    margin: 0 0 0 10px;
    padding: 1px 12px;
    background-color: #2b303a;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 0 10px 10px 0;
    min-height: 20px;
}

.messagesView-submitButton--active {
    background-color: #179a83;
    cursor: pointer;
    color: #ffffff;
}
.messagesView-submitButton--active:focus,
.messagesView-submitButton--active:hover {
    background-color: #1ab49a;
    outline: none;
}
