.leftNav {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 910;
    top: 0;
    left: 0;
    color: #fff;
    background-color: #262626;
    overflow-x: hidden;
    padding: 10px;
}

.leftNav-mask {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 900;
    top: 0;
    left: 0;
}

.leftNav-header {
    display: flex;
}

.leftNav-accountInfo {
    display: flex;
    align-items: center;
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
    width: 100%;
    padding: 7px;
    margin-left: 10px;
    font-weight: 600;
}

.leftNav-initials {
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    font-weight: 600;
    padding: 5px;
    margin-right: 10px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    min-width: 30px;
    font-size: 20px;
}

.leftNav-name {

}

.leftNav-closeButton {
    height: 100%;
    min-width: 50px;
    width: 50px;
    cursor: pointer;
    border: none;
    background: none;
    padding: 15px ;
}

.leftNav-closeButtonIcon {
    height: 25px;
    min-width: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/close-white.svg");
}

.leftNav-menuOptions {
    display: flex;
    flex-direction: column;
    margin: 20px 10px 0;
}

.leftNav-topMenuOptions {
    display: flex;
    flex-direction: column;
}

.leftNav-topMenuOptions a,
.leftNav-topMenuOptions button,
.leftNav-menuOptions a,
.leftNav-menuOptions button {
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 15px;
    text-align: left;
    padding: 6px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.leftNav-menuOptions a:focus,
.leftNav-menuOptions a:hover,
.leftNav-menuOptions button:focus,
.leftNav-menuOptions button:hover {
    outline: none;
    background-color: #3f3f3f;
}

.leftNav-logout {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.leftNav-listsIcon {
    width: 15px;
    height: 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/home.svg");
}

.leftNav-listPreferencesIcon {
    width: 15px;
    height: 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/lists.svg");
}

.leftNav-tagIcon {
    width: 15px;
    height: 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/tag-white.svg");
    transform: rotate(-45deg);
}

.leftNav-linkIcon {
    width: 15px;
    height: 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/link.svg");
}

#leftNav-hiddenInput {
    width: 1px;
    height: 1px;
    padding: 0;
    border: none;
    opacity: 0;
}

.leftNav-settingsIcon {
    width: 15px;
    height: 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/settings-light.svg");
}

.leftNav-logoutIcon {
    width: 15px;
    height: 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/logout.svg");
}

.leftNav-menuOptionText {
    margin-left: 15px;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.leftNav-badge {
    background-color: #9A031E;
    color: #fff;
    padding: 3px;
    border-radius: 3px;
    font-size: .7em;
    min-width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
