.adminUtils {
}

.adminUtils-mainContent {
    padding: 10px;
}

.adminUtils label {
    font-weight: 600;
}

.adminUtils-searchInputWrapper {
    position: relative;
}

.adminUtils .adminUtils-searchInput {
    margin: 0 0 10px 0;
    font-size: 1em;
}

.adminUtils-searchInputCloseButton {
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-62%);
    transform: translateY(-62%);
    padding: 6px;
    cursor: pointer;
}
.adminUtils-searchInputCloseButton:hover,
.adminUtils-searchInputCloseButton:focus {
    background-color: #ececec;
}

.adminUtils-closeBlackIcon {
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../../images/icons/close-grey.svg");
}

.adminUtils-error {
    color: #ff3135;
    font-size: .8em;
    padding: 10px;
}

.adminUtils-userButton {
    padding: 10px;
    border: none;
    border-bottom: 1px solid #e6e6e6;
    background: none;
    margin: 0;
    width: 100%;
    cursor: pointer;
    text-align: left;
    font-size: .85em;
    display: flex;
    justify-content: space-between;
}
.adminUtils-userButton:last-child {
    border-bottom: none;
}
.adminUtils-userButton:hover,
.adminUtils-userButton:focus {
    background-color: #fff4d2;
}
.adminUtils-userButton:disabled {
    cursor: unset;
    background-color: #FFF;
}

.adminUtils-selectedLabel {
    font-size: .75em;
    text-transform: capitalize;
    border: 1px solid #1f72ff;
    border-radius: 10px;
    padding: 2px 7px;
    background-color: #1f72ff;
    color: #FFFFFF;
}

.adminUtils-noUsers {
    padding: 10px;
    font-size: .85em;
}

.adminUtils-buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.adminUtils-cancelButton {
    background-color: #ececec;
    cursor: pointer;
    flex: 1;
    padding: 15px;
    margin: 0;
    border-bottom-left-radius: 10px;
    border: 1px solid #aaaaaa;
    border-right: none;
}
.adminUtils-cancelButton:focus,
.adminUtils-cancelButton:hover {
    background-color: #dbdbdb;
}

.adminUtils-submitButton {
    cursor: pointer;
    flex: 1;
    padding: 15px;
    margin: 0;
    border-bottom-right-radius: 10px;
    border: 1px solid #05b385;
    background-color: #05b385;
    color: #fff;
}
.adminUtils-submitButton:focus,
.adminUtils-submitButton:hover {
    background-color: #05956d;
}
.adminUtils-submitButton:disabled {
    border: 1px solid #aaaaaa;
    background-color: #ececec;
    color: #a8a8a8;
    cursor: unset;
}
