.cookbook {

}

.cookbook-iconButton {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    cursor: pointer;
}

.cookbook-icon {
    height: 50px;
    width: 50px;
    min-height: 50px;
    min-width: 50px;
    margin-left: 15px;
}

.cookbook-cookbookActions {
    margin: 20px 20px 0;
    display: flex;
    flex-direction: column;
}
.tablet .cookbook-cookbookActions {
    flex-direction: row;
    justify-content: space-between;
}

.cookbook-cookbookPrimaryActions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.tablet .cookbook-cookbookPrimaryActions {
    flex-direction: row;
}

.cookbook-addRecipeButton {
    border-radius: 5px;
    background-color: #3FEEA5;
    border: 2px solid #3FEEA5;
    padding: 5px 10px;
    font: inherit;
    font-weight: 700;
    color: #2d333c;
    cursor: pointer;
    margin: 0 0 10px;
}
.cookbook-addRecipeButton:hover {
    background-color: #42ffb3;
}
.tablet .cookbook-addRecipeButton {
    margin: 0 10px 0 0;
}

.cookbook-viewAllCookbookButton,
.cookbook-editCookbookButton {
    border-radius: 5px;
    border: 2px solid #4386ee;
    padding: 5px 10px;
    font: inherit;
    font-weight: 700;
    background-color: #4386ee;
    color: #fff;
    cursor: pointer;
    margin: 0 0 10px;
}
.tablet .cookbook-viewAllCookbookButton,
.tablet .cookbook-editCookbookButton {
    margin: 0 10px 0 0;
}
.cookbook-viewAllCookbookButton:hover,
.cookbook-editCookbookButton:hover {
    border: 2px solid #4793ff;
    background-color: #4793ff;
}

.cookbook-cookbookSecondaryActions {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-top: 10px;
}
.tablet .cookbook-cookbookSecondaryActions {
    align-self: unset;
    margin-top: 0;
}

.cookbook-cookbookSecondaryActions span {
    margin-right: 7px;
    font-weight: 500;
    color: #6e6e6e;
}

.cookbook-sortDropdown {

}

.cookbook-sortDropdownButton {
    border-radius: 5px;
    border: 2px solid #4386ee;
    padding: 5px 10px;
    font: inherit;
    font-weight: 700;
    background-color: #4386ee;
    color: #fff;
    cursor: pointer;
    margin: 0;
}
.cookbook-sortDropdownButton:hover {
    border: 2px solid #4793ff;
    background-color: #4793ff;
}

.cookbook-sortDropdownMenu {
    z-index: 200;
}

.cookbook-sortDropdownMenu button {
    width: 100%;
    padding: 7px 5px;
    margin: 0;
    background: none;
    border: none;
    border-bottom: 1px solid #000;
    cursor: pointer;
    color: #000;
}
.cookbook-sortDropdownMenu button:hover {
    background-color: #f8f8f8;
}
.cookbook-sortDropdownMenu button:last-child {
    border-bottom: none;
}

.cookbook-sortDirectionButton {
    border: none;
    border-radius: 3px;
    padding: 3px 3px 0;
    margin: 0 0 0 7px;
    background: none;
    cursor: pointer;
}
.tablet .cookbook-sortDirectionButton {
    margin: 0 0 0 3px;
}
.cookbook-sortDirectionButton:hover {
    background-color: #ffffff;
}

.cookbook-sortDirectionIcon {
    width: 20px;
    height: 20px;
}

.cookbook-sortDirectionIcon--flip {
    -moz-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1);
}

.contentCard.cookbook-pendingDeletionWarning {
    display: flex;
    flex-direction: column;
    background-color: #ff4a3d;
    margin: 20px 20px 0;
}
.tablet .cookbook-pendingDeletionWarning {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cookbook-pendingDeletionWarning span {
    font-weight: 700;
}

.cookbook-pendingDeletionWarning button {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px 10px;
    font: inherit;
    font-weight: 700;
    background-color: #4386ee;
    color: #fff;
    cursor: pointer;
    margin: 10px 0 0;
}
.tablet .cookbook-pendingDeletionWarning button {
    margin: 0 0 0 10px;
}

.cookbook-recipePreviews {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
}
.tablet .cookbook-recipePreviews {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.desktop4k .cookbook-recipePreviews {
    justify-content: flex-start;
}

.cookbook-noRecipesMessage {
    margin: 20px;
    padding: 10px 50px;
    color: #404040;
    border: 2px solid #7e7e7e;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
}
