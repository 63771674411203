.editModal {
}

.editModal-mainContent {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.editModal-mainContent label {
    font-weight: 600;
    font-size: 18px;
}

.editModal-label,
.editModal-customLink,
.editModal-price {
    margin-left: 0;
    font-size: 1em;
}

.editModal-description {
    margin: 5px 0 14px;
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid #a9a9a9;
    resize: vertical;
    min-height: 1em;
    height: 3em;
}

.editModal-priceContainer {
    display: flex;
}
.editModal-priceDollarSign {
    padding-top: 2px;
}
.editModal-priceField {
    flex-grow: 1;
}

.editModal-buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.editModal-buttonContainer button {
    background-color: #ececec;
    cursor: pointer;
    flex: 1;
    padding: 15px;
    margin: 0;
}
.editModal-buttonContainer button:focus,
.editModal-buttonContainer button:hover {
    background-color: #dbdbdb;
}

.editModal-cancelButton {
    border-bottom-left-radius: 10px;
    border: 1px solid #aaaaaa;
    border-right: none;
}

.editModal-submitButton {
    border-bottom-right-radius: 10px;
    border: 1px solid #aaaaaa;
}
