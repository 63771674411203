.modal {

}

.modal-mask {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    top: 0;
    left: 0;
}

.modal-window {
    z-index: 1010;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
    background-color: #fff;
    border-radius: 10px;
    max-width: 100%;
    overflow: hidden;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background-color: #dbdbdb;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 1px solid #bfbfbf;
}

.modal-titleContainer {
    display: flex;
    align-items: center;
}

.modal-title {
    text-align: center;
}

.modal-headerLeft,
.modal-headerRight {
    display: flex;
    align-items: stretch;
    min-width: 55px;
}

.modal-closeButton {
    height: 100%;
    width: 100%;
    cursor: pointer;
    border: none;
    background: none;
    padding: 15px ;
    border-top-right-radius: 10px;
}
.modal-closeButton:hover,
.modal-closeButton:focus {
    background-color: #cfcfcf;
    outline: none;
}

.modal-closeButtonIcon {
    height: 25px;
    width: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../images/icons/close.svg");
}
