.confirmationModal-message {
    padding: 10px;
}

.confirmationModal-buttonContainer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #bfbfbf;
}

.confirmationModal-cancelButton,
.confirmationModal-confirmButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 20px;
    font-size: 16px;
    flex: 1;
}
.confirmationModal-cancelButton:focus,
.confirmationModal-cancelButton:hover,
.confirmationModal-confirmButton:focus,
.confirmationModal-confirmButton:hover {
    background-color: #eeeeee;
}
.confirmationModal-confirmButton {
    border-bottom: 1px solid #dfdfdf;
}

.confirmationModal-confirmButton--destructive {
    color: #d63334;
}
