.sousChef {
    background-color: #eaeaea;
    min-height: 100vh;
    overflow: auto;
    display: flex;
}

.sousChef-sideBar {
    width: 300px;
    min-width: 300px;
    background-color: #001427;
    color: #fff;
}

.sousChef-sideBarMask {
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
}
.desktopLg .sousChef-sideBarMask {
    display: none;
}

.sousChef-sideBar h1 {
    padding-left: 20px;
}

.sousChef-sideBarTitleButton {
    background: none;
    border: none;
    font: inherit;
    text-align: left;
    cursor: pointer;
    color: #fff;
    padding: 0;
    margin: 0;
}

.sousChef-sideBarLinks {
    display: flex;
    flex-direction: column;
}

.sousChef-sideBarLink {
    color: #fff;
    text-decoration: none;
    padding: 3px 0 3px 20px;
    background: none;
    border: none;
    font: inherit;
    text-align: left;
    cursor: pointer;
}
.sousChef-sideBarLink:hover {
    background-color: #002843;
}

.sousChef-content {
    flex: 1;
    position: relative;
    min-width: 300px;
}
