.requestRow {
    background-color: #353a46;
    padding: 10px;
    margin: 10px 0;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    color: #FFF;
    font-size: 15px;
    text-align: left;
    cursor: pointer;
}
.requestRow:focus,
.requestRow:hover {
    background-color: #434857;
}

.requestRow--highlighted {
    border: 2px solid #dabe5e;
}

.requestRow-left {
    flex: 1;
}

.requestRow-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.requestRow-titleInfo {
    font-size: .65em;
    font-weight: normal;
    margin-bottom: 3px;
}

.requestRow-name {
    font-weight: 600;
    font-size: 16px;
    background: none;
    border: none;
}
.mobileMd .requestRow-name {
    font-size: 18px;
}
.desktop .requestRow-name {
    font-size: 1em;
}

.requestRow-description {
    margin-top: 5px;
    font-size: .8em;
}
.desktop .requestRow-description {
    font-size: .6em;
}

.requestRow-viewInviteButton {
    border: none;
    border-radius: 5px;
    min-height: 35px;
    cursor: pointer;
    background-color: #006fd6;
    color: #fff;
    font-size: 1em;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px 20px;
}
.tablet .requestRow-viewInviteButton {
    margin-right: 10px;
    flex-direction: row;
    padding: 2px 15px 0;
}
.requestRow-viewInviteButton:focus,
.requestRow-viewInviteButton:hover {
    background-color: #008eff;
}

.requestRow-viewInviteButton span:last-child {
    margin-left: 5px;
}
