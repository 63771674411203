.message {
    margin-top: 10px;
}
.message.message--headerHidden {
    margin-top: 0;
}

.message.message--unread {
    margin-top: 10px;
}

.message--unread .message-unreadText {
    color: #f03a47;
    padding: 5px 0;
    font-size: .75em;
    border-bottom: 1px solid #f03a47;
    margin-bottom: 5px;
}
.tablet .message-unreadText {
    padding-bottom: 3px;
}

.message-header {
    font-weight: 600;
    font-size: .85em;
}
.message--headerHidden .message-header {
    display: none;
}

.message-time {
    font-weight: normal;
    color: #7e8691;
    font-size: .8em;
    margin-left: 5px;
}

.message-bodyContainer {
    display: inline-flex;
    padding: 2px 0;
    align-items: center;
}

.message-body {
    display: inline-flex;
    background-color: #006ec6;
    padding: 2px 7px;
    border-radius: 7px;
    align-items: center;
}
button.message-body {
    padding: 2px 5px 2px 7px;
    cursor: pointer;
    border: none;
    color: #fff;
    font-size: 1em;
    text-align: left;
}

.message-optionsIcon {
    min-width: 5px;
    width: 5px;
    height: 17px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../images/icons/3_dots_grey.svg");
    margin-left: 5px;
}

.message-options {
    background-color: #fff;
    border-radius: 7px;
    display: flex;
    margin-left: 5px;
}

.message-optionsButton {
    background: none;
    border: none;
    padding: 1px 10px;
    margin: 1px 0;
    font-size: 1em;
    border-right: 1px solid #2b303a;
    cursor: pointer;
}
.message-optionsButton:last-child {
    border-right: none;
}

.message-optionsButton--destructive {
    color: #d63334;
}

.message-form {
}

.message-editField {
    overflow: hidden;
    border-radius: 10px;
}

.message .message-edit {
    background-color: #fff;
    margin: 0;
    padding: 5px 0 5px 5px;
    font-size: 1em;
    min-height: 20px;
}
.tablet .message-edit {
    padding: 2px 0 2px 5px;
}
.message .message-edit:focus {
    border-bottom-color: #fff;
}

.message-editableMessageButtons {
    display: flex;
    justify-content: flex-end;
    margin: 5px 0;
}

.message-cancelEditButton,
.message-saveEditButton {
    border: none;
    font-size: 1em;
    min-width: 75px;
    padding: 5px;
    cursor: pointer;
}

.message-cancelEditButton {
    background-color: #a9a9a9;
}
.message-cancelEditButton:focus,
.message-cancelEditButton:hover {
    background-color: #c4c4c4;
    outline: none;
}

.message-saveEditButton {
    background-color: #179a83;
    margin-left: 5px;
    color: #fff;
}
.message-saveEditButton:focus,
.message-saveEditButton:hover {
    background-color: #1ab49a;
    outline: none;
}

.message-editedLabel {
    font-size: .7em;
    color: #7e8691;
    margin-bottom: 4px;
}
.tablet .message-editedLabel {
    font-size: .6em;
}
