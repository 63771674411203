.addGift {
    margin: 15px 0;
}

.addGift-mainSection {
    display: flex;
    align-items: center;
    border: 1px solid #c5c5c5;
    border-radius: 10px;
}

.addGift-labelField {
    flex-grow: 1;
}

.addGift-label {
    margin: 0;
    padding-left: 10px;
    font-size: 1em;
    border: none;
    min-height: 20px;
}
.addGift-label::placeholder {
    color: #c5c5c5;
}
.addGift-label:focus {
    border-bottom-color: #c5c5c5;
}

.addGift-submitButton {
    margin: 0 0 0 10px;
    background: none;
    color: #c5c5c5;
    border: none;
    border-left: 1px solid #c5c5c5;
    border-radius: 0 10px 10px 0;
    min-height: 32px;
}

.addGift-submitButton--active {
    background-color: #179a83;
    border: 1px solid #179a83;
    cursor: pointer;
    color: #ffffff;
}

.addGift-submitButton--active:focus,
.addGift-submitButton--active:hover {
    background-color: #1ab49a;
    border: 1px solid #1ab49a;
    outline: none;
}

.addGift-moreOptions {
    margin: 0 20px;
}

.addGift-moreOptionInputs {
    margin-bottom: -2px;
    max-height: 0;
    transition: max-height 0.25s ease-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tablet .addGift-moreOptionInputs {
    flex-direction: row;
}

.addGift-moreOptionInputs--expanded {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
}

.addGift-moreOptionInputs--contentRendered {
    padding: 15px;
    border: 1px solid #c5c5c5;
    border-top: none;
    border-bottom-right-radius: 10px;
}

.addGift-moreOptionInputs label {
    font-size: 13px;
    cursor: pointer;
}

.addGift-moreOptionsButton {
    border: 1px solid #c5c5c5;
    border-top: none;
    background: none;
    border-radius: 0 0 10px 10px;
    padding: 3px 15px;
    color: #ababab;
    cursor: pointer;
}
.addGift-moreOptionsButton:focus,
.addGift-moreOptionsButton:hover {
    outline: none;
    background-color: #63a8ff;
    border-color: #63A8FF;
    color: #fff;
}

.addGift-leftSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.tablet .addGift-leftSection {
    padding-right: 30px;
}

.addGift-rightSection {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.addGift-description {
    height: 100%;
    resize: vertical;
    font-family: Arial, Helvetica, sans-serif;
    min-height: 1em;
    border: 1px solid #a9a9a9;
    margin-bottom: 25px;
    font-size: 1em;
}

.addGift-customLink {
    margin: 0 0 25px 0;
    font-size: 1em;
}

.addGift-priceWrapper {
    display: flex;
    align-items: center;
}

.addGift-priceDollarSign {
    margin-bottom: 2px;
    margin-right: 1px;
}

.addGift-priceField {
    flex-grow: 1;
}

.addGift-price {
    margin: 0;
    font-size: 1em;
}

.addGift input::-webkit-outer-spin-button,
.addGift input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.addGift input[type=number] {
    -moz-appearance:textfield;
}