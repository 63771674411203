.joinView-errorNotice {
    background-color: #f5cf87;
    color: #000;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.joinView-errorNoticeIcon {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../../images/icons/warning.svg");
    margin-right: 10px;
}

.joinView-banner {
    background-color: #353a46;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.joinView-mainText {
    font-size: 1.25em;
}

.joinView-subtext {
    font-size: .8em;
}

.joinView-buttonContainer {
    display: flex;
    margin-top: 50px;
    justify-content: center;
}
.tablet .joinView-buttonContainer--right,
.tablet .joinView-buttonContainer {
    margin-top: 50px;
}

.joinView-buttonContainer--right {
    display: flex;
    margin-top: 50px;
    justify-content: flex-end;
}

.joinView-cancelButton,
.joinView-sendRequestButton,
.joinView-acceptButton,
.joinView-declineButton {
    border: none;
    border-radius: 5px;
    height: 60px;
    cursor: pointer;
    margin: 0 10px;
    flex: 1;
    color: #fff;
    font-size: 1.25em;
    padding: 0 12px;
    min-width: 75px;
}
.tablet .joinView-cancelButton,
.tablet .joinView-sendRequestButton,
.tablet .joinView-acceptButton,
.tablet .joinView-declineButton {
    height: 35px;
    flex: unset;
    font-size: 1em;
}
.joinView-cancelButton:disabled,
.joinView-sendRequestButton:disabled,
.joinView-acceptButton:disabled,
.joinView-declineButton:disabled {
    background: none;
    border: 2px solid #949494;
    color: #949494;
    cursor: default;
}

.joinView-sendRequestButton,
.joinView-acceptButton {
    background-color: #46934a;
}
.joinView-sendRequestButton:focus:enabled,
.joinView-sendRequestButton:hover:enabled,
.joinView-acceptButton:focus:enabled,
.joinView-acceptButton:hover:enabled {
    background-color: #4db161;
}

.joinView-declineButton {
    background-color: #A42B3A;
}
.joinView-declineButton:focus:enabled,
.joinView-declineButton:hover:enabled {
    background-color: #cb3545;
}

.joinView-sendRequestButton {
    background-color: #2163cd;
}
.joinView-sendRequestButton:focus:enabled,
.joinView-sendRequestButton:hover:enabled {
    background-color: #1f72ff;
}

.joinView-cancelButton {
    background-color: #3d4556;
}
.joinView-cancelButton:focus:enabled,
.joinView-cancelButton:hover:enabled {
    background-color: #495769;
}

.joinView-buttonInner {
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.joinView-declineIcon,
.joinView-acceptIcon {
    width: 35px;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 5px;
}
.tablet .joinView-declineIcon,
.tablet .joinView-acceptIcon {
    width: 25px;
    height: 25px;
}

.joinView-acceptIcon {
    background-image: url("../../../../../images/icons/checkmark-white.svg");
}

.joinView-declineIcon {
    background-image: url("../../../../../images/icons/close-white.svg");
}
