.input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #e6e6e6;
    margin: 0 7px 14px;
    padding: 5px 0;
    width: 100%;
}

.input:focus {
    outline: none;
    border-bottom-color: #6395e7;
}