.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    height: 100%;
}

.dropdown-expandIcon {
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../images/icons/down_arrow.svg");
    margin-left: 5px;
    overflow: hidden;
}

.dropdown-menu {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    background-color: #fff;
    right: 0;
    top: 100%;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}