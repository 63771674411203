.fieldLabel {

}

.fieldLabel-errorText {
    font-size: .8em;
    color: #ff484a;
    font-weight: 500;
}

.fieldLabel label {
    font-weight: 700;
}

.fieldLabel-infoMessage {
    font-size: .7em;
    color: #9c7333;
}
