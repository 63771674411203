.inviteModal {
}

.inviteModal-mainContent {
    padding: 10px;
}

.inviteModal label {
    font-weight: 600;
}

.inviteModal-searchInputWrapper {
    position: relative;
}

.inviteModal .inviteModal-searchInput {
    margin: 0 0 10px 0;
    font-size: 1em;
}

.inviteModal-errors {
    color: #ff3135;
    font-size: .8em;
    padding-left: 20px;
    margin-top: 0;
}

.inviteModal-searchInputCloseButton {
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-62%);
    transform: translateY(-62%);
    padding: 6px;
    cursor: pointer;
}
.inviteModal-searchInputCloseButton:hover,
.inviteModal-searchInputCloseButton:focus {
    background-color: #ececec;
}

.inviteModal-closeBlackIcon {
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/close-grey.svg");
}

.inviteModal-recipients {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
}
.tablet .inviteModal-recipients {
    margin: 5px 0;
}

.inviteModal-recipient {
    font-size: .75em;
    display: flex;
    align-items: center;
    margin: 2px;
    background-color: #1f72ff;
    color: #fff;
}

.inviteModal-recipientLabel {
    padding: 5px;
}

.inviteModal-removeRecipientButton {
    margin: 0;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}
.tablet .inviteModal-removeRecipientButton {
    padding: 6px;
}
.inviteModal-removeRecipientButton:focus,
.inviteModal-removeRecipientButton:hover {
    background-color: #185bb2;
}

.inviteModal-closeWhiteIcon {
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/close-white.svg");
}

.inviteModal-userButton {
    padding: 10px;
    border: none;
    border-bottom: 1px solid #e6e6e6;
    background: none;
    margin: 0;
    width: 100%;
    cursor: pointer;
    text-align: left;
    font-size: .85em;
    display: flex;
    justify-content: space-between;
}
.inviteModal-userButton:last-child {
    border-bottom: none;
}
.inviteModal-userButton:hover,
.inviteModal-userButton:focus {
    background-color: #fff4d2;
}
.inviteModal-userButton:disabled {
    cursor: unset;
    background-color: #FFF;
}

.inviteModal-pendingLabel,
.inviteModal-selectedLabel,
.inviteModal-rank {
    font-size: .75em;
    text-transform: capitalize;
    border: 1px solid #9f9f9f;
    border-radius: 10px;
    padding: 2px 7px;
}

.inviteModal-selectedLabel {
    background-color: #1f72ff;
    border-color: #1f72ff;
    color: #FFFFFF;
}

.inviteModal-pendingLabel {
    background-color: #ffdfae;
    border-color: #ffdfae;
}

.inviteModal-noUsers {
    padding: 10px;
    font-size: .85em;
}

.inviteModal-buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inviteModal-cancelButton {
    background-color: #ececec;
    cursor: pointer;
    flex: 1;
    padding: 15px;
    margin: 0;
    border-bottom-left-radius: 10px;
    border: 1px solid #aaaaaa;
    border-right: none;
}
.inviteModal-cancelButton:focus,
.inviteModal-cancelButton:hover {
    background-color: #dbdbdb;
}

.inviteModal-submitButton {
    cursor: pointer;
    flex: 1;
    padding: 15px;
    margin: 0;
    border-bottom-right-radius: 10px;
    border: 1px solid #05b385;
    background-color: #05b385;
    color: #fff;
}
.inviteModal-submitButton:focus,
.inviteModal-submitButton:hover {
    background-color: #05956d;
}
.inviteModal-submitButton:disabled {
    border: 1px solid #aaaaaa;
    background-color: #ececec;
    color: #a8a8a8;
    cursor: unset;
}
