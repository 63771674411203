.cookbooksWidget {

}

.cookbooksWidget-noCookbooksPlaceholder {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.desktop .cookbooksWidget-noCookbooksPlaceholder {
    flex-direction: row;
}

.cookbooksWidget-cookbookShadow {
    height: 175px;
    width: 140px;
    background-color: #eaeaea;
    align-self: center;
    margin: 15px 27px;
}

.cookbooksWidget-line {
    align-self: stretch;
    height: 3px;
    background-color: #818181;
    border-radius: 3px;
    margin: 10px 0 20px;
}
.desktop .cookbooksWidget-line {
    width: 3px;
    height: auto;
    margin: 0 20px 0 10px;
}

.cookbooksWidget-recipePlaceholderText {
    color: #959595;
}

.cookbooksWidget-cookbookActions {
    margin: 20px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.tablet .cookbooksWidget-cookbookActions {
    flex-direction: row;
}

.cookbooksWidget-showMoreButton {
    border-radius: 5px;
    border: 2px solid #4386ee;
    padding: 5px 10px;
    font: inherit;
    font-weight: 700;
    background-color: #4386ee;
    color: #fff;
    cursor: pointer;
    margin: 0;
}
.cookbooksWidget-showMoreButton:hover {
    border: 2px solid #4793ff;
    background-color: #4793ff;
}

.cookbooksWidget-createCookbookButton {
    border-radius: 5px;
    background-color: #3FEEA5;
    border: 2px solid #3FEEA5;
    padding: 5px 10px;
    font: inherit;
    font-weight: 700;
    color: #2d333c;
    cursor: pointer;
    margin: 0;
}
.cookbooksWidget-createCookbookButton:hover {
    background-color: #42ffb3;
}
