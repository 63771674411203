.recipePreview {
    border: none;
    border-radius: 10px;
    font: inherit;
    cursor: pointer;
    text-align: center;
    color: inherit;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 0 0 20px 0;
}
.tablet .recipePreview {
    margin: 0 20px 20px 0;
}
.tablet .recipePreview:last-child {
    margin-right: 0;
}
.recipePreview:hover {
    background-color: #fff5e1;
}

.recipePreview-filler {
    width: 340px;
}

.recipePreview-contentCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    overflow: hidden;
    background: none;
}
.tablet .contentCard.recipePreview-contentCard {
    margin: 0;
    width: 300px;
}

.recipePreview-coverPhoto {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 100px;
}

.spinner.recipePreview-spinner {
    position: unset;
}

.recipePreview-image {
    width: 100%;
    z-index: 100;
}

.recipePreview-icon {
    height: 150px;
    width: 150px;
    margin: 10px 0;
}

.recipePreview-text {
    padding: 3px 5px;
}

.recipePreview-name {
    font-weight: 700;
    font-size: 1.1em;
}

.recipePreview-tags {
    font-size: 0.85em;
    color: #636363;
}

.recipePreview-deletionWarning {
    font-weight: 700;
    color: #ff3c3a;
    margin-top: 5px;
}
