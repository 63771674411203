.recipe {

}

.recipe-errorMessage {
    background-color: #ff6f75;
}

.recipe-errorMessage h3 {
    margin-bottom: 5px;
}

.recipe-recipeActions {
    margin: 20px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.tablet .recipe-recipeActions {
    flex-direction: row;
}

.recipe-wakeLockButton,
.recipe-editRecipeButton,
.recipe-viewExternalRecipeButton {
    border-radius: 5px;
    border: 2px solid #4386ee;
    padding: 5px 10px;
    font: inherit;
    font-weight: 700;
    background-color: #4386ee;
    color: #fff;
    cursor: pointer;
    margin: 10px 0 0;
}
.tablet .recipe-wakeLockButton,
.tablet .recipe-editRecipeButton,
.tablet .recipe-viewExternalRecipeButton {
    margin: 0 10px 0 0;
}

.recipe-wakeLockButton {
    border-radius: 5px;
    border: 2px solid #3FEEA5;
    padding: 5px 10px;
    font: inherit;
    font-weight: 700;
    background-color: #3FEEA5;
    color: #2d333c;
    cursor: pointer;
    margin: 10px 0 0;
}
.tablet .recipe-wakeLockButton {
    margin: 0 10px 0 0;
}
.recipe-wakeLockButton.recipe-wakeLockButton--disable {
    background-color: #ee6153;
    border-color: #ee6153;
}

.recipe-redWarning {
    display: flex;
    flex-direction: column;
    background-color: #ff4a3d;
}
.tablet .recipe-redWarning {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.recipe-redWarning span {
    font-weight: 700;
}

.recipe-redWarning button {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px 10px;
    font: inherit;
    font-weight: 700;
    background-color: #4386ee;
    color: #fff;
    cursor: pointer;
    margin: 10px 0 0;
}
.tablet .recipe-redWarning button {
    margin: 0 0 0 10px;
}

.recipe-contentCard {

}

.recipe-photo.photoPreview--unselected img.photoPreview-image {
    width: 100%;
    max-width: 800px;
    max-height: 600px;
}

.recipe-hardcopyPhoto.photoPreview--unselected img.photoPreview-image {
    width: 300px;
}

.recipe-icon {
    height: 400px;
    width: 400px;
    max-height: 400px;
    max-width: calc(100% - 10px);
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    padding: 5px;
}

.recipe-labels,
.recipe-notes,
.recipe-timeEstimates {
    margin-bottom: 10px;
}

.recipe-labels span {
    font-weight: 700;
}

.recipe-notes {
    background-color: #fef6be;
    border: 1px solid #9c9c9c;
    padding: 5px 10px;
    border-radius: 5px;
}

.recipe-notesHeader {
    font-weight: 700;
    font-size: 1.1em;
    margin-bottom: 3px;
}

.recipe h3 {
    margin: 0;
    font-size: 1.2em;
}

.recipe ol,
.recipe ul {
    margin-top: 5px;
    padding-left: 23px;
}

.recipe-ingredient {
    margin-bottom: 5px;
    font-size: 1.2em;
}

.recipe-ingredient--selected {
    text-decoration: underline;
}

.recipe-ingredientQuantity {
    font-weight: 700;
}

.recipe-ingredientPhotos {
    display: flex;
    flex-wrap: wrap;
}

.recipe-ingredientPhoto {
    margin: 0 10px 10px 0;
}

.recipe-ingredientPhoto.photoPreview--unselected img.photoPreview-image {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
}

.recipe-instruction {
    margin-bottom: 10px;
    padding: 5px 10px;
    font-size: 1.2em;
    cursor: pointer;
    display: flex;
    border-radius: 5px;
    border: 1px solid #fff;
}

.recipe-instruction--selected {
    background-color: #fef6be;
    border: 1px solid #9c9c9c;
}

.recipe-instructionNumber {
    margin-right: 10px;
    font-weight: 500;
}

.recipe-instructionPhoto {
    margin-top: 10px;
}
.recipe-instructionPhoto.photoPreview--unselected img.photoPreview-image {
    width: 300px;
    max-width: 300px;
}

.recipe-hardcopyRecipe h3 {
    margin-bottom: 10px;
}

.recipe-hardcopyPhotos .photoPreview--unselected {
    margin: 0 10px 10px 0;
}

.recipe-showPhotosButton {
    border-radius: 5px;
    border: 2px solid #4386ee;
    padding: 5px 10px;
    font: inherit;
    font-weight: 700;
    background-color: #4386ee;
    color: #fff;
    cursor: pointer;
    margin: 0;
}
