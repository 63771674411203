.titleBar {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
}
.tablet .titleBar {
    flex-direction: row;
    align-items: center;
}

.titleBar-primary {
    display: flex;
    align-items: center;
}

.titleBar-hamburgerButton {
    background: none;
    border: none;
    margin: 0;
    padding: 5px 5px 0;
    cursor: pointer;
    border-radius: 5px;
}
.titleBar-hamburgerButton:hover {
    background-color: #eaeaea;
}

.titleBar-hamburgerIcon {
    height: 28px;
    width: 28px;
    min-height: 28px;
    min-width: 28px;
}

.titleBar-titles {
    margin-left: 10px;
}

.titleBar-primaryTitleSection {
    display: flex;
    align-items: flex-end;
}

.titleBar-primaryTitleSection span {
    margin: 0 0 3px 5px;
    color: #4c4c4c;
}

.titleBar h2 {
    margin: 0;
    padding: 0;
}

.titleBar-subTitle {
    color: #4f4f4f;
}
button.titleBar-subTitle {
    background: none;
    border: none;
    font: inherit;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-align: left;
}
button.titleBar-subTitle:hover {
    text-decoration: underline;
}

.titleBar-searchForm {
    width: 100%;
}
.tablet .titleBar-searchForm {
    width: unset;
}

.titleBar-searchBar {
    background-color: #eaeaea;
    padding: 7px 15px;
    border: none;
    font-size: 1em;
    border-radius: 15px;
    width: calc(100% - 40px);
    margin-top: 10px;
}
.titleBar-searchBar:focus {
    outline: none;
}
.tablet .titleBar-searchBar {
    width: 200px;
    margin-top: 0;
}
.desktopLg .titleBar-searchBar {
    width: 400px;
}