.timeEstimateFields {
    background-color: #eaeaea;
    border-radius: 10px;
    margin-bottom: 8px;
    border: 2px solid #fff;
}
.timeEstimateFields:last-child {
    margin-bottom: 0;
}

.timeEstimateFields-droppable {
    border: 2px solid #70a2ff;
}

.timeEstimateFields-estimate {
    display: flex;
    align-items: center;
    position: relative;
}
.timeEstimateFields-droppable .timeEstimateFields-estimate {
    visibility: hidden;
}

.timeEstimateFields-grabIconContainer {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 0 5px;
    cursor: grab;
}

.timeEstimateFields-grabIcon {
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
}

.timeEstimateFields-content {
    padding: 7px 7px 7px 0;
    display: flex;
    align-items: center;
    flex: 1;
}
.tablet .timeEstimateFields-content {
    padding: 5px 5px 5px 0;
}

.timeEstimateFields-number {
    margin-right: 10px;
}

.timeEstimateFields-fieldsAndButtons {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.tablet .timeEstimateFields-fieldsAndButtons {
    flex-direction: row;
}

.timeEstimateFields-formFields {
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-size: 0.8em;
}
.tablet .timeEstimateFields-formFields {
    flex-direction: row;
}

.timeEstimateFields-descriptionSection {
    display: flex;
    flex-direction: column;
}

.timeEstimateFields-durationAndUnits {
    display: flex;
    align-items: flex-end;
}

.timeEstimateFields-durationSection {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-right: 10px;
}
.tablet .timeEstimateFields-durationSection {
    margin-top: 0;
    margin-left: 10px;
}

.timeEstimateFields-unitsSection {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.tablet .timeEstimateFields-unitsSection {
    justify-content: flex-start;
}

.timeEstimateFields-durationLabel,
.timeEstimateFields-descriptionLabel {
    margin-bottom: 3px;
}

.timeEstimateFields-durationLabel .fieldLabel-errorTextAndLabel,
.timeEstimateFields-descriptionLabel .fieldLabel-errorTextAndLabel {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}

.timeEstimateFields-durationLabel label,
.timeEstimateFields-descriptionLabel label {
    font-weight: 500;
    margin-right: 5px;
}

.timeEstimateFields-durationInput,
.timeEstimateFields-descriptionInput {
    font: inherit;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
    flex: 1;
}

.timeEstimateFields-durationInput {
    width: 100px;
}

.timeEstimateFields-timeUnitButton {
    font: inherit;
    font-size: 0.9em;
    border-radius: 5px;
    color: #919191;
    border: 1px solid #a6a6a6;
    padding: 3px 7px;
    margin: 0 5px 0 0;
    cursor: pointer;
    background: none;
}
.timeEstimateFields-timeUnitButton:hover {
    color: #6b6b6b;
    border-color: #6b6b6b;
    background-color: #d9d9d9;
}

.timeEstimateFields-timeUnitButton.timeEstimateFields-timeUnitButton--active {
    color: #fff;
    background-color: #4386ee;
    border-color: #4386ee;
}

.timeEstimateFields-buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 30px;
}
.tablet .timeEstimateFields-buttonContainer {
    margin: 0 10px;
    min-width: 110px;
    justify-content: center;
}

.timeEstimateFields-buttonContainer.timeEstimateFields-buttonContainer--stretch {
    width: 100%;
}

.timeEstimateFields-deleteButton {
    border-radius: 5px;
    background-color: #ee5759;
    border: none;
    padding: 5px 7px;
    font: inherit;
    font-size: 0.7em;
    font-weight: 700;
    color: #2d333c;
    cursor: pointer;
}
.tablet .timeEstimateFields-deleteButton {
    padding: 3px 7px;
    width: unset;
}
.timeEstimateFields-deleteButton:hover {
    background-color: #ff5c5e;
}

.timeEstimateFields-deleteNag {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    background-color: rgba(216, 216, 216, 0.8);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tablet .timeEstimateFields-deleteNag {
    flex-direction: row;
}

.timeEstimateFields-deleteNagText {
    background-color: #ffffff;
    padding: 5px 10px;
    border-radius: 10px;
}

.timeEstimateFields-deleteNagButtonContainer {
    display: flex;
    margin-top: 20px;
    align-self: stretch;
    justify-content: space-between;
}
.tablet .timeEstimateFields-deleteNagButtonContainer {
    margin-top: 0;
    margin-left: 20px;
    align-self: center;
    justify-content: flex-end;
    flex: 1;
}

.timeEstimateFields-yesButton {
    border-radius: 5px;
    background-color: #ee5759;
    border: none;
    padding: 5px 15px;
    font: inherit;
    font-weight: 700;
    color: #2d333c;
    cursor: pointer;
}
.tablet .timeEstimateFields-yesButton {
    margin-right: 40px;
}

.timeEstimateFields-noButton {
    border-radius: 5px;
    background-color: #3f3f3f;
    border: none;
    padding: 5px 15px;
    font: inherit;
    font-weight: 700;
    color: #dbdbdb;
    cursor: pointer;
}
