.alertPreferences {
    padding: 8px 16px;
    border-bottom: 1px solid #aaa;
}

.alertPreferences-mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.alertPreferences-title {
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 45px;
}
.tablet .alertPreferences-title {
    height: 32px;
}

.alertPreferences-doneButton {
    background-color: #ececec;
    border: none;
    font-size: 13px;
    padding: 7px 15px;
    margin-left: 8px;
    cursor: pointer;
}
.alertPreferences-doneButton:hover,
.alertPreferences-doneButton:focus {
    background-color: #dbdbdb;
}

.alertPreferences-preferencesGroup {
    margin: 8px 0;
}
.alertPreferences-preferencesGroup:last-child {
    margin: 16px 0;
}

.alertPreferences-note {
    font-size: .75em;
    color: #a7914f;
    padding: 8px;
    font-weight: 600;
}
.tablet .alertPreferences-note {
    padding: 4px 8px;
}

.alertPreferences-header {
    border-bottom: 1px solid #000;
    font-weight: 600;
    padding-bottom: 8px;
    font-size: .9em;
}

.alertPreferences-checkboxHeader {
    flex-direction: column-reverse;
    align-items: center;
}
.tablet .alertPreferences-checkboxHeader {
    flex-direction: row;
}

.alertPreferences-row {
    font-size: .8em;
}
.alertPreferences-row:nth-of-type(odd) {
    background-color: #e5e5e5;
}

.alertPreferences-rowWrapper {
    display: flex;
    align-items: center;
    padding: 8px;
}
.tablet .alertPreferences-rowWrapper {
    padding: 4px 8px;
}

.alertPreferences-rowLabel {
    width: 50%;
    font-weight: 600;
}

.alertPreferences-rowSubtext {
    font-weight: normal;
    font-size: .75em;
}

.alertPreferences-rowValue {
    width: 25%;
    display: flex;
    justify-content: center;
}

.alertPreferences-clickable {
    cursor: pointer;
}
