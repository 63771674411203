.cookbookForm {
}

.cookbookForm-contentCard {
    max-width: 700px;
    font-size: 1.25em;
}

.cookbookForm-errorMessenger {
    max-width: 700px;
}

.cookbookForm-form {
    display: flex;
    flex-direction: column;
}

.cookbookForm-fieldGroup {
    display: flex;
    margin-bottom: 25px;
    flex-direction: column;
}
.tablet .cookbookForm-fieldGroup {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.cookbookForm-fieldGroup.cookbookForm-authorsFieldGroup {
    margin-bottom: 5px;
}

.cookbookForm-fieldGroup.cookbookForm-iconSelector {
    flex-direction: column;
    align-items: stretch;
}

.cookbookForm-input {
    font-size: 1em;
    margin-top: 5px;
    height: 1.1em;
}
.tablet .cookbookForm-input {
    margin-top: 0;
}

.cookbookForm-toggle {
    margin-top: 10px;
}
.tablet .cookbookForm-toggle {
    margin-top: 0;
    align-self: flex-start;
}

.cookbookForm-selectedAuthors {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.cookbookForm-author {
    font-size: 0.8em;
    background-color: #395dff;
    margin: 0 0 5px 5px;
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    color: #fff;
}

.cookbookForm-authorName {
    padding: 5px 5px 5px 10px;
}

.cookbookForm-removeAuthorButton {
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 7px 7px;
    font: inherit;
    font-size: 0.9em;
    font-weight: 700;
    cursor: pointer;
    background: none;
}
.cookbookForm-removeAuthorButton:hover {
    background-color: #4d7dff;
}

.cookbookForm-closeIcon {
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/close-white.svg");
}

.cookbookForm-dropdown .dropdown-value {
    display: flex;
    flex-direction: column;
}

.cookbookForm-authorOption {
    width: 100%;
    border: none;
    padding: 7px 15px;
    font: inherit;
    font-size: 0.8em;
    cursor: pointer;
    background: none;
    border-bottom: 1px solid #000;
    color: #000;
}
.cookbookForm-authorOption:hover {
    background-color: #eeeeee;
}
.cookbookForm-authorOption:last-child {
    border-bottom: none;
}

.cookbookForm-iconField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 20px;
    flex-direction: column;
}
.desktop .cookbookForm-iconField {
    padding-top: 10px;
    flex-direction: row-reverse;
}

.cookbookForm-icon {
    height: 125px;
    width: 125px;
    margin-bottom: 30px;
}
.desktop .cookbookForm-icon {
    margin-bottom: 0;
}

.cookbookForm-iconPreviews {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
.desktop .cookbookForm-iconPreviews {
    width: 250px;
    margin-bottom: 0;
}

.cookbookForm-iconPreview {
    height: 50px;
    width: 50px;
}
.desktop .cookbookForm-iconPreview {
    height: 40px;
    width: 40px;
}

.cookbookForm-iconButton {
    background: none;
    padding: 2px;
    margin: 0 2px 2px 0;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 5px;
}
.cookbookForm-iconButton:hover {
    background-color: #ececec;
}

.cookbookForm-iconButton--selected {
    border: 2px solid #000000;
}

.cookbookForm-themePreviews {
    display: flex;
    flex-wrap: wrap;
}
.desktop .cookbookForm-themePreviews {
    width: 180px;
}

.cookbookForm-themeButton {
    height: 50px;
    width: 50px;
    background: none;
    padding: 0;
    margin: 0 3px 3px 0;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 5px;
}
.desktop .cookbookForm-themeButton {
    height: 40px;
    width: 40px;
}
.cookbookForm-themeButton:hover {
    filter: brightness(135%);
}

.cookbookForm-themeButton--selected {
    border: 2px solid #000;
}

.cookbookForm-note {
    background-color: #ffefc5;
    font-size: 0.75em;
    padding: 10px 15px;
    border-radius: 5px;
    text-align: center;
    align-self: center;
}

.cookbookForm-undoLink {
    color: #1f72ff;
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
}

.cookbookForm-buttonGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.cookbookForm-secondaryButtons {
    display: flex;
    justify-content: space-between;
}

.cookbookForm-deleteRecipeButton,
.cookbookForm-cancelButton,
.cookbookForm-submitButton {
    border: none;
    border-radius: 5px;
    padding: 7px 15px;
    font: inherit;
    font-size: 0.9em;
    font-weight: 700;
    cursor: pointer;
}

.cookbookForm-cancelButton {
    background-color: #5B616A;
    margin-right: 5px;
    color: #fff;
}
.cookbookForm-cancelButton:hover {
    background-color: #6c727b;
}

.cookbookForm-deleteRecipeButton {
    background-color: #ff4c4c;
    color: #fff;
    flex: 1;
}
.cookbookForm-deleteRecipeButton:hover {
    background-color: #ff6d68;
}
.tabletSm .cookbookForm-deleteRecipeButton {
    flex: unset;
}

.cookbookForm-submitButton {
    background-color: #3FEEA5;
    color: #2d333c;
}
.cookbookForm-submitButton:hover {
    background-color: #42ffb3;
}

.cookbookForm-deleteSection {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}
.tabletSm .cookbookForm-deleteSection {
    justify-content: space-between;
    flex-direction: row;

}

.cookbookForm-deletionPrompt {
    color: #ff4c4c;
    font-weight: 500;
}

.cookbookForm-deletionHelpText {
    font-size: 0.7em;
    color: #9c7333;
}

.cookbookForm-deletionButtonSection {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.tabletSm .cookbookForm-deletionButtonSection {
    margin-top: 0;
}

.cookbookForm-deletionButtonSection button {
    flex: 1;
    border: none;
    border-radius: 5px;
    font: inherit;
    margin: 0;
    padding: 5px 10px;
    cursor: pointer;
}
.tabletSm .cookbookForm-deletionButtonSection button {
    flex: unset;

}

button.cookbookForm-deletionYesButton {
    background-color: #ff4c4c;
    color: #fff;
    margin-right: 5px;
}
button.cookbookForm-deletionYesButton:hover {
    background-color: #ff6d68;
}

button.cookbookForm-deletionNoButton {
    background-color: #5B616A;
    color: #fff;
}
button.cookbookForm-deletionNoButton:hover {
    background-color: #6c727b;
}
