.recipeForm {

}

.recipeForm-contentCard {
    max-width: 1000px;
    font-size: 1.25em;
}

.recipeForm-errorMessage {
    max-width: 1000px;
    font-size: 1.25em;
    background-color: #ff5f62;
}

.recipeForm-errorMessage span {
    font-weight: 700;
}

.recipeForm-fieldGroup {
    display: flex;
    margin-bottom: 35px;
    flex-direction: column;
}
.tablet .recipeForm-fieldGroup {
    margin-bottom: 25px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.recipeForm-fieldGroup.recipeForm-fieldGroupColumn {
    flex-direction: column;
    align-items: stretch;
}

.recipeForm-fieldGroup.recipeForm-timeEstimatesFieldGroup,
.recipeForm-fieldGroup.recipeForm-ingredientsFieldGroup,
.recipeForm-fieldGroup.recipeForm-instructionsFieldGroup,
.recipeForm-fieldGroup.recipeForm-tagsFieldGroup {
    margin-bottom: 5px;
}

.recipeForm-photoIconORContainer {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.tablet .recipeForm-photoIconORContainer {
    margin-top: 0;
    margin-left: 15px;
}

.recipeForm-greenButton {
    border-radius: 5px;
    background-color: #3FEEA5;
    border: 2px solid #3FEEA5;
    padding: 5px 10px;
    font: inherit;
    font-size: 0.8em;
    font-weight: 700;
    color: #2d333c;
    cursor: pointer;
}
.recipeForm-greenButton:hover {
    background-color: #42ffb3;
    border: 2px solid #42ffb3;
}

.recipeForm-photoIconButtonContainer .recipeForm-uploadPhotoButton {
    margin-left: 10px;
}

.recipeForm-addEstimatesButton,
.recipeForm-addIngredientsButton,
.recipeForm-addInstructionsButton,
.recipeForm-originalRecipePhotosInput {
    margin-top: 10px;
}
.tablet .recipeForm-addEstimatesButton,
.tablet .recipeForm-addIngredientsButton,
.tablet .recipeForm-addInstructionsButton,
.tablet .recipeForm-originalRecipePhotosInput {
    margin-top: 0;
    width: 160px;
}

.tablet .recipeForm-orSeparator {
    margin: 0 25px;
}

.recipeForm-selectIconButton {
    border-radius: 5px;
    border: 2px solid #4386ee;
    padding: 5px 10px;
    font: inherit;
    font-size: 0.8em;
    font-weight: 700;
    background-color: #4386ee;
    color: #fff;
    cursor: pointer;
}
.recipeForm-selectIconButton:hover {
    border: 2px solid #4793ff;
    background-color: #4793ff;
}
.recipeForm-photoIconButtonContainer .recipeForm-selectIconButton {
    margin-left: 7px;
}

.recipeForm-photoPicker {

}

.recipeForm-photoPreview {
    margin: 0 20px 20px 0;
}

.recipeForm-photoFileInput {
    display: none;
}

.recipeForm-iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tablet .recipeForm-iconContainer {
    flex-direction: row-reverse;
    justify-content: space-around;
}

.recipeForm-icon {
    height: 100px;
    width: 100px;
    min-height: 100px;
    min-width: 100px;
    border: 3px solid #000;
    padding: 5px;
}

.recipeForm-iconPreviews {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px 0 0;
}
.tablet .recipeForm-iconPreviews {
    margin: 0 20px 0 0;
    width: 60%;
}

.recipeForm-iconPickerButton {
    background: none;
    padding: 1px;
    margin: 1px;
    cursor: pointer;
    border: 2px solid #fff;
}
.recipeForm-iconPickerButton:hover {
    background-color: #ececec;
}

.recipeForm-iconPickerButton--selected {
    border: 2px solid #000000;
}

.recipeForm-iconPreview {
    height: 40px;
    width: 40px;
    margin: 0 5px 5px 0;
}

.recipeForm-photoIconButtonContainer {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tablet .recipeForm-photoIconButtonContainer {
    justify-content: flex-start;
}
.recipeForm-iconPicker .recipeForm-photoIconButtonContainer {
    justify-content: center;
    align-items: center;
}

.recipeForm-input {
    font-size: 1em;
    margin-top: 5px;
    height: 1.1em;
    width: unset;
}
.tablet .recipeForm-input {
    margin-top: 0;
    width: 300px;
}

.recipeForm-timeEstimates,
.recipeForm-instructions,
.recipeForm-ingredients {
    margin-bottom: 10px;
}

.recipeForm-timeEstimateButtons,
.recipeForm-ingredientButtons,
.recipeForm-instructionButtons {
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
}
.tablet .recipeForm-timeEstimateButtons,
.tablet .recipeForm-ingredientButtons,
.tablet .recipeForm-instructionButtons {
    margin-bottom: 25px;
}

.recipeForm-notesTextArea {
    margin-top: 10px;
    font: inherit;
    border-radius: 5px;
}

.recipeForm-dropdown .dropdown-value {
    display: flex;
    flex-direction: column;
}

.recipeForm-tagOption {
    width: 100%;
    border: none;
    padding: 7px 15px;
    font: inherit;
    font-size: 0.8em;
    cursor: pointer;
    background: none;
    border-bottom: 1px solid #000;
    color: #000;
}
.recipeForm-tagOption:hover {
    background-color: #eeeeee;
}
.recipeForm-tagOption:last-child {
    border-bottom: none;
}

.recipeForm-toggle {
    margin-top: 10px;
}
.tablet .recipeForm-toggle {
    margin-top: 0;
    align-self: flex-start;
}

.recipeForm-selectedTags {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.recipeForm-tag {
    font-size: 0.8em;
    background-color: #395dff;
    margin: 0 0 5px 5px;
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    color: #fff;
}

.recipeForm-tagName {
    padding: 5px 5px 5px 10px;
}

.recipeForm-removeTagButton {
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 7px 7px;
    font: inherit;
    font-size: 0.9em;
    font-weight: 700;
    cursor: pointer;
    background: none;
}
.recipeForm-removeTagButton:hover {
    background-color: #4d7dff;
}

.recipeForm-closeIcon {
    width: 15px;
    height: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/close-white.svg");
}

.recipeForm-buttonGroup {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
}
.tabletSm .recipeForm-buttonGroup {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.recipeForm-secondaryButtons {
    display: flex;
    justify-content: space-between;
}

.recipeForm-deleteRecipeButton,
.recipeForm-cancelButton,
.recipeForm-submitButton {
    border: none;
    border-radius: 5px;
    padding: 7px 15px;
    font: inherit;
    font-size: 0.9em;
    font-weight: 700;
    cursor: pointer;
    margin: 5px 0 0;
}

.recipeForm-cancelButton {
    background-color: #5B616A;
    color: #fff;
    margin-right: 5px;
    flex: 1;
}
.recipeForm-cancelButton:hover {
    background-color: #6c727b;
}
.tabletSm .recipeForm-cancelButton {
    flex: unset;
}

.recipeForm-deleteRecipeButton {
    background-color: #ff4c4c;
    color: #fff;
    flex: 1;
}
.recipeForm-deleteRecipeButton:hover {
    background-color: #ff6d68;
}
.tabletSm .recipeForm-deleteRecipeButton {
    flex: unset;
}

.recipeForm-submitButton {
    background-color: #3FEEA5;
    color: #2d333c;
}
.recipeForm-submitButton:hover {
    background-color: #42ffb3;
}

.recipeForm-deleteSection {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}
.tabletSm .recipeForm-deleteSection {
    justify-content: space-between;
    flex-direction: row;

}

.recipeForm-deletionPrompt {
    color: #ff4c4c;
    font-weight: 500;
}

.recipeForm-deletionHelpText {
    font-size: 0.7em;
    color: #9c7333;
}

.recipeForm-deletionButtonSection {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.tabletSm .recipeForm-deletionButtonSection {
    margin-top: 0;
}

.recipeForm-deletionButtonSection button {
    flex: 1;
    border: none;
    border-radius: 5px;
    font: inherit;
    margin: 0;
    padding: 5px 10px;
    cursor: pointer;
}
.tabletSm .recipeForm-deletionButtonSection button {
    flex: unset;

}

button.recipeForm-deletionYesButton {
    background-color: #ff4c4c;
    color: #fff;
    margin-right: 5px;
}
button.recipeForm-deletionYesButton:hover {
    background-color: #ff6d68;
}

button.recipeForm-deletionNoButton {
    background-color: #5B616A;
    color: #fff;
}
button.recipeForm-deletionNoButton:hover {
    background-color: #6c727b;
}
