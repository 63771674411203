.passwordReset {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.tabletSm .passwordReset {
    width: 66%;
}

.desktop .passwordReset {
    width: 33%;
}

.passwordReset-title {
    font-size: 17px;
    margin-bottom: 20px;
}

.passwordReset-error {
    color: #ff0000;
    font-size: 12px;
}

.passwordReset-input {
    width: 100%;
}

.passwordReset-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    width: 100%;
}

.passwordReset-submitButton {
    align-self: flex-end;
    background-color: #4b8ec8;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 13px;
    border: none;
    padding: 7px 15px;
    cursor: pointer;
}

.passwordReset-submitButton:hover,
.passwordReset-submitButton:focus {
    background-color: #56aaee;
}
