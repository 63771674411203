.alerts {
    height: 100%;
}

.alerts-dropdown {
    height: 100%;
}

.alerts .alerts-dropdownButton {
    width: 55px;
    display: flex;
    justify-content: center;
    padding: 5px;
}
.mobileLg .alerts .alerts-dropdownButton {
    width: 65px;
    padding: unset;
}

.alerts-dropdownButton:focus,
.alerts-dropdownButton:hover {
    background-color: #e6e6e6;
    outline: none;
}

.alerts-badge {
    position: relative;
}

.alerts-inactiveBadge {
    width: 35px;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../../images/icons/inactive_bell.svg");
    overflow: hidden;
}

.alerts-activeBadge {
    width: 35px;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../../images/icons/active_bell.svg");
    overflow: hidden;
}

.alerts-badgeCount {
    background-color: #9A031E;
    color: #fff;
    padding: 3px;
    border-radius: 3px;
    font-size: .75em;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 10px;
}

.alerts-menuWrapper {
    margin-bottom: 50px;
}

.alerts-menu {
    width: 400px;
    max-width: calc(100vw - 20px);
    padding: 10px;
}

.alerts-notificationHeaderBar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.alerts-notificationHeader {
    font-weight: 500;
    font-size: 1.15em;
}

.alerts-notificationSubHeader {
    font-size: .75em;
}

.alerts-name {
    font-weight: 600;
}

.alerts-markReadButton {
    border: none;
    background: none;
    padding: 5px;
    color: #00a4ee;
    cursor: pointer;
    font-size: .75em;
}

.alerts-markReadButton:hover,
.alerts-markReadButton:focus {
    text-decoration: underline;
}

.alerts-moreButton {
    border: none;
    background: none;
    padding: 5px 0 10px;
    width: 100%;
    color: #00a4ee;
    cursor: pointer;
    font-weight: 500;
    font-size: 1em;
}

.alerts-moreButton:hover,
.alerts-moreButton:focus {
    text-decoration: underline;
}

.alerts-danger {
    color: #f20307;
    font-weight: 600;
}
