.recentlyDeleted {

}

.recentlyDeleted-deletedItemPreviews {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
}
.tablet .recentlyDeleted-deletedItemPreviews {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.desktop4k .recentlyDeleted-deletedItemPreviews {
    justify-content: flex-start;
}

.recentlyDeleted-nothingRecentlyDeletedMessage {
    margin: 20px;
    padding: 10px 50px;
    color: #404040;
    border: 2px solid #7e7e7e;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
}
