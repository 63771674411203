.alert {
    padding: 10px;
    border-bottom: 1px solid #c5c5c5;
    font-size: .9em;
    transition: background-color 1s;
}

.alert--clickable {
    cursor: pointer;
}

.alert:last-child {
    border-bottom: none;
}

.alert--unread {
    background-color: #fbf1d7;
    transition: background-color 1s;
}

.alert-subRow {
    display: flex;
    justify-content: space-between;
}

.alert-createdAt {
    font-size: .75em;
    cursor: default;
    padding: 5px 0;
}

.alert-markReadButton {
    border: none;
    background: none;
    padding: 5px;
    color: #00a4ee;
    cursor: pointer;
    font-size: .75em;
}

.alert-markReadButton:hover,
.alert-markReadButton:focus {
    text-decoration: underline;
}