.inviteLinkModal {
    padding: 20px;
}

.inviteLinkModal-enableSection {
    display: flex;
    justify-content: space-between;
}

.inviteLinkModal-enableSectionText {
    padding-right: 30px;
}

.inviteLinkModal-subText {
    font-size: .75em;
}

.inviteLinkModal-inviteLink {
    border: 1px solid #000;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
}

.inviteLinkModal-link {
    font-size: .85em;
    color: #757575;
    padding: 5px 3px;
    white-space: nowrap;
    flex: 1;
    border: none;
}
.tablet .inviteLinkModal-link {
    font-size: .75em;
}

.inviteLinkModal-copyButton {
    margin: 0;
    background-color: #006ec6;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: .85em;
}
.inviteLinkModal-copyButton:focus,
.inviteLinkModal-copyButton:hover {
    background-color: #007ed9;
}
