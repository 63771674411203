.hubRow {
    background-color: #353a46;
    padding: 10px;
    margin: 10px 0;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    height: 80px;
}
.desktop .hubRow {
    min-height: 55px;
    height: 55px;
}

.hubRow-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.hubRow--joined {
    cursor: pointer;
    border: none;
    width: 100%;
    color: #FFF;
    font-size: 15px;
    text-align: left;
}
.hubRow--joined:focus,
.hubRow--joined:hover {
    background-color: #434857;
}

.hubRow-badge {
    background-color: #9A031E;
    color: #fff;
    padding: 3px;
    border-radius: 3px;
    font-size: .7em;
    min-width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.hubRow-name {
    font-weight: 600;
    font-size: 16px;
}
.mobileMd .hubRow-name {
    font-size: 18px;
}
.desktop .hubRow-name {
    font-size: 1em;
}

.hubRow-description {
    margin-top: 5px;
    font-size: .8em;
}
.desktop .hubRow-description {
    font-size: .6em;
}

.hubRow-role {
    color: #fff;
    font-size: .6em;
    text-transform: capitalize;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 3px 7px 2px;
}

