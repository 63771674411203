.copyModal-mainContent {
    padding: 14px;
    display: flex;
    flex-direction: column;
}

.copyModal-mainContent label {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 18px;
}

.copyModal-toList {
    font-size: 16px;
    min-height: 16px;
    padding: 5px 2px;
    cursor: pointer;
}

.copyModal-buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.copyModal-buttonContainer button {
    background-color: #ececec;
    cursor: pointer;
    flex: 1;
    padding: 15px;
    margin: 0;
}
.copyModal-buttonContainer button:focus,
.copyModal-buttonContainer button:hover {
    background-color: #dbdbdb;
}

.copyModal-cancelButton {
    border-bottom-left-radius: 10px;
    border: 1px solid #aaaaaa;
    border-right: none;
}

.copyModal-submitButton {
    border-bottom-right-radius: 10px;
    border: 1px solid #aaaaaa;
}