.authenticator {
    background-color: #FFFFFF;
    color: #2B303A;
    width: 75%;
    margin: 25px auto;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tabletSm .authenticator {
    margin: 50px auto;
}

.authenticator-icons {
    position: relative;
}

.authenticator-giftIcon {
    height: 200px;
    width: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.authenticator-tagIcon {
    height: 50px;
    width: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 53%;
    left: 46%;
}

.authenticator-title {
    color: #F03A47;
    font-weight: 600;
    font-size: 45px;
    font-family: Zian;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 25px;
    letter-spacing: 2px;
}

.tablet .authenticator-title {
    font-size: 55px;
}

.desktop .authenticator-title {
    font-size: 65px;
}