.interstitial {
}
.interstitial.interstitial--small {
    height: 35px;
}
.interstitial.interstitial--medium {
    height: 125px;
}
.interstitial.interstitial--large {
    height: 300px;
}

.interstitial .interstitial-hidden {
    visibility: hidden;
}

.interstitial-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.interstitial-container {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.interstitial-singleTagInterstitial {
    background-image: url("../../images/icons/tag-green.svg");
    transform: rotate(-45deg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
}
.interstitial--small .interstitial-singleTagInterstitial {
    height: 25px;
    width: 25px;
}
.interstitial--medium .interstitial-singleTagInterstitial {
    height: 35px;
    width: 35px;
}
.interstitial--large .interstitial-singleTagInterstitial {
    height: 50px;
    width: 50px;
}

.interstitial--small .interstitial-text {
    display: none;
}
.interstitial--medium .interstitial-text {
    margin-top: 15px;
    font-size: 0.8em;
}
.interstitial--large .interstitial-text {
    margin-top: 25px;
}

@-moz-keyframes spin {
    from { -moz-transform:rotate(0deg); }
    to { -moz-transform:rotate(359deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform:rotate(0deg); }
    to { -webkit-transform:rotate(359deg); }
}
@-ms-keyframes spin {
    from { -webkit-transform:rotate(0deg); }
    to { -webkit-transform:rotate(359deg); }
}
@-o-keyframes spin {
    from { -webkit-transform:rotate(0deg); }
    to { -webkit-transform:rotate(359deg); }
}
@keyframes spin {
    from { transform:rotate(0deg); }
    to { transform:rotate(359deg); }
}