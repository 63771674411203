.search {
}

.search-form {
    display: flex;
    align-items: stretch;
    flex-direction: column;
}

.search-searchBar {
    font-size: 1.5em;
    margin: 20px;
    border: 1px solid #868686;
    outline: none;
    border-radius: 20px;
    padding: 5px 15px;
}

.search-recipePreviews {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
}
.tablet .search-recipePreviews {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.desktop4k .search-recipePreviews {
    justify-content: flex-start;
}

.search-noRecipesMessage {
    margin: 20px;
    padding: 10px 50px;
    color: #404040;
    border: 2px solid #7e7e7e;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
}
