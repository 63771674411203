.ingredientFields {
    background-color: #eaeaea;
    border-radius: 10px;
    margin-bottom: 8px;
    border: 2px solid #fff;
}
.ingredientFields:last-child {
    margin-bottom: 0;
}

.ingredientFields-droppable {
    border: 2px solid #70a2ff;
}

.ingredientFields-ingredient {
    display: flex;
    align-items: center;
    position: relative;
}
.ingredientFields-droppable .ingredientFields-ingredient {
    visibility: hidden;
}

.ingredientFields-grabIconContainer {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 0 5px;
    cursor: grab;
}

.ingredientFields-grabIcon {
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
}

.ingredientFields-content {
    padding: 7px 7px 7px 0;
    display: flex;
    align-items: center;
    flex: 1;
}
.tablet .ingredientFields-content {
    padding: 5px 5px 5px 0;
}

.ingredientFields-number {
    margin-right: 10px;
}

.ingredientFields-fieldsAndButtons {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.tablet .ingredientFields-fieldsAndButtons {
    flex-direction: row;
}

.ingredientFields-formFields {
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-size: 0.8em;
}
.tablet .ingredientFields-formFields {
    flex-direction: row;
    align-items: center;
}

.ingredientFields-quantitySection {
    margin-right: 15px;
}

.ingredientFields-descriptionSection {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.tablet .ingredientFields-descriptionSection {
    margin-right: 10px;
    margin-top: 0;
    flex: 1;
}

.ingredientFields-descriptionLabel,
.ingredientFields-quantityLabel {
    margin-bottom: 3px;
}

.ingredientFields-descriptionLabel .fieldLabel-errorTextAndLabel,
.ingredientFields-quantityLabel .fieldLabel-errorTextAndLabel {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}

.ingredientFields-descriptionLabel label,
.ingredientFields-quantityLabel label {
    font-weight: 500;
    margin-right: 5px;
}

.ingredientFields-quantityInput {
    font: inherit;
    width: 100px;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
}

.ingredientFields-descriptionInput {
    font: inherit;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
}


.ingredientFields-photoAndButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.tablet .ingredientFields-photoAndButtons {
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
}

.photoPreview-image.ingredientFields-photoPreview {
    width: 100px;
    max-width: 100%;
    margin: 15px 13px 7px 7px;
}
.tablet .photoPreview-image.ingredientFields-photoPreview {
    width: 100px;
    margin: 7px 13px 7px 7px;
}
.photoPreview--selected .photoPreview-image.ingredientFields-photoPreview {
    width: unset;
    max-width: 80vw;
    max-height: 90vh;
}

.ingredientFields-photo .photoPreview-closeButton {
    top: 4px;
    right: 4px;
}

.ingredientFields-buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 10px;
    width: 50%;
}
.tablet .ingredientFields-buttonContainer {
    margin: 0 10px;
    width: 100%;
    min-width: 110px;
}

.ingredientFields-buttonContainer.ingredientFields-buttonContainer--stretch {
    width: 100%;
}

.ingredientFields-buttonContainer button {
    border-radius: 5px;
    border: none;
    padding: 5px 7px;
    font: inherit;
    font-size: 0.7em;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 7px;
}
.tablet .ingredientFields-buttonContainer button {
    padding: 3px 7px;
    width: unset;
    margin-bottom: 5px;
}

.ingredientFields-addPhotoButton {
    background-color: #3FEEA5;
    color: #2d333c;
}
.ingredientFields-addPhotoButton:hover {
    background-color: #42ffb3;
}

.ingredientFields-deleteButton {
    background-color: #ee5759;
    color: #2d333c;
}
.tablet button.ingredientFields-deleteButton {
    margin-bottom: 0;
}
.ingredientFields-deleteButton:hover {
    background-color: #ff5c5e;
}

.ingredientFields-selectButton--select {
    background-color: #4386ee;
    color: #fff;
}
.ingredientFields-selectButton--select:hover {
        background-color: #4493fc;
}

.ingredientFields-selectButton--unselect {
    background-color: #7d7d7d;
    color: #fff;
}
.ingredientFields-selectButton--unselect:hover {
    background-color: #8f8f8f;
}

.ingredientFields-deleteNag {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    background-color: rgba(216, 216, 216, 0.8);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tablet .ingredientFields-deleteNag {
    flex-direction: row;
}

.ingredientFields-deleteNagText {
    background-color: #ffffff;
    padding: 5px 10px;
    border-radius: 10px;
}

.ingredientFields-deleteNagButtonContainer {
    display: flex;
    margin-top: 20px;
    align-self: stretch;
    justify-content: space-between;
}
.tablet .ingredientFields-deleteNagButtonContainer {
    margin-top: 0;
    margin-left: 20px;
    align-self: center;
    justify-content: flex-end;
    flex: 1;
}

.ingredientFields-yesButton {
    border-radius: 5px;
    background-color: #ee5759;
    border: none;
    padding: 5px 15px;
    font: inherit;
    font-weight: 700;
    color: #2d333c;
    cursor: pointer;
}
.tablet .ingredientFields-yesButton {
    margin-right: 40px;
}

.ingredientFields-noButton {
    border-radius: 5px;
    background-color: #3f3f3f;
    border: none;
    padding: 5px 15px;
    font: inherit;
    font-weight: 700;
    color: #dbdbdb;
    cursor: pointer;
}

.ingredientFields-photoFileInput {
    display: none;
}
