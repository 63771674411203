.contributorModal {}

.contributorModal-content {
    padding: 10px;
}

.contributorModal-contentSubtitle {
    font-size: .75em;
    margin-bottom: 15px;
}
.tablet .contributorModal-contentSubtitle {
    margin-bottom: 10px;
}

.contributorModal-hub {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.tablet .contributorModal-hub {
    margin-bottom: 10px;
}

.contributorModal-hubName {
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.tablet .contributorModal-hubName {
    align-self: flex-start;
}

.contributorModal .contributorModal-checkbox {
    margin-right: 10px;
}
.tablet .contributorModal .contributorModal-checkbox {
    margin-right: 5px;
    min-height: 15px;
    min-width: 15px;
    width: 15px;
    height: 15px;
}
.tablet .contributorModal-checkbox .checkbox-checkmark {
    width: 10px;
    height: 10px;
}

.contributorModal-members {
    margin: 3px 0 0 10px;
    border-left: 2px solid #dbdbdb;
    padding: 0 0 0 10px;
}
.tablet .contributorModal-members {
    margin: 3px 0 0 7px;
    padding: 0 0 0 7px;
}

.contributorModal-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
}
.tablet .contributorModal-member {
    padding: 0 5px;
}
.contributorModal-member:hover,
.contributorModal-member:focus {
    background-color: #eeeeee;
}

.contributorModal .contributorModal-member--disabled,
.contributorModal .contributorModal-member--disabled .checkbox {
    cursor: default;
}
.contributorModal .contributorModal-member--disabled:hover,
.contributorModal .contributorModal-member--disabled:focus {
    background: none;
}

.contributorModal-memberName {
    display: flex;
    align-items: center;
}

.contributorModal-greenTagIcon,
.contributorModal-yellowTagIcon,
.contributorModal-greyTagIcon {
    height: 22px;
    width: 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    transform: rotate(-45deg);
}
.contributorModal-greyTagIcon {
    background-image: url("../../../../images/icons/tag-grey.svg");
}
.contributorModal-greenTagIcon {
    background-image: url("../../../../images/icons/tag-green.svg");
}
.contributorModal-yellowTagIcon {
    background-image: url("../../../../images/icons/tag-yellow.svg");
}

.contributorModal-buttonContainer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #bfbfbf;
}

.contributorModal-cancelButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 20px;
    font-size: 16px;
    flex: 1;
    margin: 0;
}
.contributorModal-cancelButton:focus,
.contributorModal-cancelButton:hover {
    background-color: #eeeeee;
}

.contributorModal-confirmButton {
    background-color: #05b385;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 20px;
    font-size: 16px;
    flex: 1;
    margin: 0;
}
.contributorModal-confirmButton:focus,
.contributorModal-confirmButton:hover {
    background-color: #05956d;
}