.gifts {
    padding: 15px 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.tablet .gifts {
    padding: 15px 30px;
}

.gifts-collaboratorToggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding: 5px 10px;
    border: 1px solid #4D4D4D;
    border-radius: 30px;
}

.gifts-collaboratorToggleText {
    margin-left: 8px;
    font-size: 1em;
}

.gifts-completeGifts {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 10px;
}

.gifts-completedHr {
    width: 100%;
    border: none;
    border-top: 1px solid #c5c5c5;
    margin: 0;
}

.gifts-completedButtonContainer {
    background-color: white;
    position: absolute;
    padding: 0 10px;
    left: 50%;
    top: -13px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.gifts-completedButton {
    border: none;
    background: none;
    cursor: pointer;
    color: #686868;
    padding: 0;
}
.gifts-completedButton:focus,
.gifts-completedButton:hover {
    border-bottom: 1px solid #c5c5c5;
    outline: none;
}

.gifts-completedGiftsContent {
}

.gifts-completedGiftsNotice {
    color: #a7914f;
    text-align: center;
    font-size: 14px;
    margin-top: 12px;
}
