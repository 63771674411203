.dashboard {
    display: flex;
    color: #FFFFFF;
    min-height: 100vh;
    overflow: auto;
}

.dashboard-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.dashboard-giftRectificationBanner {
    background-color: #ffe39f;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.tablet .dashboard-giftRectificationBanner {
    flex-direction: row;
    max-width: 70vw;
    width: 70vw;
    margin: 30px auto 0;
    border-radius: 10px;
}

.dashboard-giftRectificationBannerMessage {
    padding: 30px;
}

.dashboard-giftRectificationButton {
    background-color: #05956d;
    border: none;
    border-radius: 10px;
    color: #fff;
    padding: 7px 10px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin: 0 30px 20px;
}
.tablet .dashboard-giftRectificationButton {
    margin: 0 30px 0 0;
    align-self: center;
    padding: 10px 12px;
}
.dashboard-giftRectificationButton:focus,
.dashboard-giftRectificationButton:hover {
    background-color: #05b385;
}

.dashboard-hubLinks {
    margin: 30px 15px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.tablet .dashboard-hubLinks {
    align-items: center;
    flex-direction: row;
    margin: 30px auto 0;
    max-width: 70vw;
    width: 70vw;
}

.dashboard-hubLinksTitle {
    margin: 0 5px 5px 0;
    font-weight: 600;
}

.dashboard-hubLinkButton {
    background: none;
    border: 1px solid #fff;
    border-radius: 20px;
    color: #fff;
    padding: 7px 15px;
    margin: 3px;
    cursor: pointer;
    font-size: .9em;
}
.tablet .dashboard-hubLinkButton {
    padding: 5px 10px;
}
.dashboard-hubLinkButton:focus,
.dashboard-hubLinkButton:hover {
    background-color: #fff;
    color: #2b303a;
}
