.logout {
    text-align: center;
    width: 90%;
}

.tabletSm .logout {
    width: 66%;
}

.desktop .logout {
    width: 33%;
}

.logout-title {
    font-size: 26px;
    margin-bottom: 50px;
}