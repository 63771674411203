.settingsView-section {
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
    margin-bottom: 10px;
}
.settingsView-section:last-child {
    border-bottom: none;
}

.settingsView-sectionHeader {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
}

.settingsView-requestsHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}
.tablet .settingsView-requestsHeader {
    flex-direction: row;
    align-items: center;
}

.settingsView-directorySubHeader,
.settingsView-requestsSubHeader {
    font-size: 13px;
    font-weight: normal;
}
.tablet .settingsView-directorySubHeader,
.tablet .settingsView-requestsSubHeader {
    font-size: 11px;
}

.settingsView-manageLinkButton {
    color: #00a4ee;
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: 1em;
}

.settingsView-requestActions {
    margin: 10px 0 0;
}
.tablet .settingsView-requestActions {
    margin: 0;
}

.settingsView-inviteButton {
    background-color: #05956d;
    border: none;
    color: #fff;
    padding: 7px 10px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin-top: 3px;
    margin-right: 10px;
}
.settingsView-inviteButton:focus,
.settingsView-inviteButton:hover {
    background-color: #05b385;
}

.settingsView-linkButton {
    background-color: #006ec6;
    border: none;
    color: #fff;
    padding: 7px 10px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin-top: 3px;
    margin-left: 10px;
}
.settingsView-linkButton:focus,
.settingsView-linkButton:hover {
    background-color: #007ed9;
}

.settingsView-requestSubtext {
    font-size: .75em;
}
.tablet .settingsView-requestSubtext {
    font-size: .65em;
}

.settingsView-cancelRequestButton {
    background: none;
    border: none;
    border-radius: 5px;
    height: 26px;
    width: 26px;
    padding: 2px 0 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    justify-self: flex-end;
}

.settingsView-cancelIcon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../../images/icons/close-grey.svg");
}
.settingsView-cancelRequestButton:focus .settingsView-cancelIcon,
.settingsView-cancelRequestButton:hover .settingsView-cancelIcon {
    background-image: url("../../../../../images/icons/close-red.svg");
}

.settingsView-request {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #353a46;
    padding: 10px;
    margin-bottom: 3px;
}
.settingsView-request:last-child {
    margin-bottom: 0;
}

.settingsView .settingsView-request--attention {
    border: 2px solid #dabe5e;
    align-items: flex-start;
    flex-direction: column;
}
.tablet .settingsView .settingsView-request--attention {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.settingsView-requestLeftColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}
.tablet .settingsView-requestLeftColumn {
    flex-direction: row;
    justify-content: space-between;
}

.settingsView-requestTitle {
    overflow-wrap: break-word;
    font-size: 1.5em;
}
.tablet .settingsView-requestTitle {
    font-size: 1.25em;
}

.settingsView-linkStatus--active {
    font-weight: 600;
    color: #2ecc71;
}

.settingsView-linkStatus--expired {
    font-weight: 600;
    color: #ff304a;

}

.settingsView-resendEmailButton {
    border: none;
    border-radius: 5px;
    min-height: 35px;
    cursor: pointer;
    background-color: #006fd6;
    color: #fff;
    font-size: 1em;
    margin-right: 10px;
    align-self: flex-start;
    margin-top: 10px;
    padding: 5px 20px;
}
.tablet .settingsView-resendEmailButton {
    margin-top: 0;
    margin-right: 20px;
    padding: 2px 15px 0;
    align-self: center;
}
.settingsView-resendEmailButton:hover,
.settingsView-resendEmailButton:focus {
    background-color: #008eff;
}
.settingsView-resendEmailButton:disabled {
    background: none;
    border: 2px solid #949494;
    color: #949494;
    cursor: default;
}

.settingsView-requestButtons {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    margin-top: 10px;
    flex: 1;
}
.tablet .settingsView-requestButtons {
    margin-top: 0;
    align-self: unset;
    justify-content: flex-end;
}

.settingsView-acceptButton,
.settingsView-declineButton {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
    color: #fff;
    font-size: 1em;
    padding: 10px 12px;
    min-width: 75px;
    flex: 1;
}
.tablet .settingsView-acceptButton,
.tablet .settingsView-declineButton {
    padding: 5px 12px;
    flex: unset;
}
.settingsView-acceptButton:disabled,
.settingsView-declineButton:disabled {
    background: none;
    border: 2px solid #949494;
    color: #949494;
    cursor: default;
}

.settingsView-acceptButton {
    background-color: #46934a;
}
.settingsView-acceptButton:focus:enabled,
.settingsView-acceptButton:hover:enabled {
    background-color: #4db161;
}

.settingsView-declineButton {
    background-color: #A42B3A;
}
.settingsView-declineButton:focus:enabled,
.settingsView-declineButton:hover:enabled {
    background-color: #cb3545;
}

.settingsView-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #353a46;
    padding: 10px;
    margin-bottom: 3px;
}
.settingsView-member:last-child {
    margin-bottom: 0;
}

.settingsView-rank {
    color: #fff;
    font-size: .75em;
    text-transform: capitalize;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 1px 7px 2px;
}
.tablet .settingsView-rank {
    font-size: .6em;
}
button.settingsView-rank {
    padding: 2px 7px;
}
button.settingsView-rank:hover,
button.settingsView-rank:focus {
    background-color: #fff;
    color: #353a46;
}

.settingsView .settingsView-rankIcon {
    width: 12px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../../images/icons/down_arrow_white.svg");
    margin-left: 3px;
    overflow: hidden;
}
button.settingsView-rank:hover .settingsView-rankIcon,
button.settingsView-rank:focus .settingsView-rankIcon {
    background-image: url("../../../../../images/icons/down_arrow.svg");
}

.settingsView-rankOption {
    cursor: pointer;
    background-color: #fff;
    border: none;
    font-size: 1em;
    border-bottom: 1px solid #000;
    padding: 7px 25px;
    margin: 0;
    width: 100%;
}
.settingsView-rankOption:hover,
.settingsView-rankOption:focus {
    background-color: #e2e2e2;
}
.tablet .settingsView-rankOption {
    font-size: .8em;
    padding: 5px 15px;
}
.settingsView-rankOption:last-child {
    border-bottom: none;
}

.settingsView-requestToggleButton,
.settingsView-memberToggleButton {
    width: 100%;
    margin: 0;
    padding: 5px 10px;
    border: none;
    background-color: #434857;
    color: #fff;
    cursor: pointer;
    font-size: 1em;
}
.settingsView-requestToggleButton:hover,
.settingsView-requestToggleButton:focus,
.settingsView-memberToggleButton:hover,
.settingsView-memberToggleButton:focus {
    background-color: #555a6c;
}

.settingsView .react-toggle-track {
    background-color: #434857;
}
.settingsView .react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
}

.settingsView-anonymizeClaimers,
.settingsView-defaultRole {
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: fit-content;
}

.settingsView-anonymizeClaimersText,
.settingsView-defaultRoleText {
    font-size: .7em;
    margin-left: 10px;
}
.tablet .settingsView-anonymizeClaimersText,
.tablet .settingsView-defaultRoleText {
    font-size: .8em;
}

.settingsView-anonymizeClaimersSubText,
.settingsView-defaultRoleSubText {
    font-size: .85em;
    font-weight: normal;
}
.tablet .settingsView-anonymizeClaimersSubText,
.tablet .settingsView-defaultRoleSubText {
    font-size: .75em;
}

.settingsView-caution {
    margin-bottom: 10px;
    font-size: .8em;
}

.settingsView-destructiveButton {
    border: none;
    cursor: pointer;
    background-color: #a62a33;
    color: #FFF;
    padding: 8px 10px;
}
.settingsView-destructiveButton:hover,
.settingsView-destructiveButton:focus {
    background-color: #d23141;
}

.settingsView-destructiveButton:disabled {
    background: none;
    border: 2px solid #6e6e6e;
    color: #6e6e6e;
    cursor: unset;
}

.settingsView-modalMain {
    margin-bottom: 15px;
}

.settingsView-modalSubMain {
    font-size: .8em;
    color: #ad5e2b;
}
