.manageListsModal {
}

.manageListsModal-listDetails {
    border-bottom: 1px solid #aaa;
}

.manageListsModal-listDetailsUpper {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.manageListsModal-deletionConfirmation {
    padding: 8px 16px;
    border-bottom: 1px solid #aaa;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.manageListsModal-editableList {
    padding: 8px 16px;
    border-bottom: 1px solid #aaa;
}

.manageListsModal-editableListMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.manageListsModal-editableListSubMain {
    margin-top: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}

.manageListsModal-editableListSubMainText {
    font-size: .75em;
    margin-left: 6px;
}

.manageListsModal-checkbox {
    min-height: 10px !important;
    min-width: 10px !important;
    width: 10px !important;
    height: 10px !important;
    padding: 1px !important;
}

.manageListsModal-checkmark {
    width: 7px !important;
    height: 7px !important;
    min-width: 7px !important;
    min-height: 7px !important;
}

.manageListsModal-selectListButton {
    background: none;
    border: none;
    padding: 0;
    font-size: 1em;
    cursor: pointer;
}
.manageListsModal-selectListButton:focus,
.manageListsModal-selectListButton:hover {
    text-decoration: underline;
}

.manageListsModal-privateLabel {
    font-size: .85em;
    color: #707070;
    margin-left: 5px;
}
.manageListsModal-editButton {
    color: #1a6aff;
    background: none;
    border: none;
    padding: 3px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.manageListsModal-editButton:hover,
.manageListsModal-editButton:focus {
    background-color: #eeeeee;
}

.manageListsModal-deleteButton {
    background: none;
    border: none;
    margin-left: 8px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.manageListsModal-deleteButton:hover,
.manageListsModal-deleteButton:focus {
    background-color: #eeeeee;
}

.manageListsModal-deleteButtonDisabled {
    background: none;
    border: none;
    margin-left: 8px;
    padding: 5px 10px;
    border-radius: 5px;
}

.manageListsModal-visibility {
    font-size: .8em;
    margin-top: 5px;
}

.manageListsModal .manageListsModal-visibilityDropdownButton {
    font-size: .9em;
    background-color: #e4e4e4;
    border-radius: 20px;
    padding: 5px 10px;
}
.tablet .manageListsModal .manageListsModal-visibilityDropdownButton {
    padding: 2px 7px;
    font-size: .8em;

}

.manageListsModal-dropdownOptionButton {
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid #a2a2a2;
    font-size: .9em;
    text-align: left;
    margin: 0;
    padding: 10px 7px;
    cursor: pointer;
}
.tablet .manageListsModal-dropdownOptionButton {
    padding: 5px 7px;
    font-size: .8em;

}
.manageListsModal-dropdownOptionButton:hover,
.manageListsModal-dropdownOptionButton:focus {
    background-color: #e5e5e5;
}
.manageListsModal-dropdownOptionButton:last-child {
    border-bottom: none;
}

.manageListsModal-sharedWith {
    background-color: #e6e6e6;
    padding: 8px 16px;
    font-size: .7em;
    display: flex;
    flex-wrap: wrap;
}

.manageListsModal-hub {
    margin: 0 20px 0 0;
    padding: 5px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.manageListsModal-hubCheckbox {
    margin-right: 5px;
    min-height: 15px;
    min-width: 15px;
    width: 15px;
    height: 15px;
}

.manageListsModal-hubCheckboxIcon {
    width: 10px;
    height: 10px;
}

.manageListsModal-addList {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    width: 100%;
    min-height: 52px;
    font-size: 1em;
}

.manageListsModal-addList:focus,
.manageListsModal-addList:hover {
    background-color: #eeeeee;
}

.manageListsModal-addList--active {
    padding: 8px 16px;
}

.manageListsModal-inputField {
    flex: 1;
}

.manageListsModal-input {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
}

.manageListsModal-cancelButton {
    background-color: #ececec;
    border: none;
    font-size: 13px;
    padding: 7px 15px;
    margin: 3px 0 3px 8px;
    cursor: pointer;
}

.manageListsModal-cancelButton:hover,
.manageListsModal-cancelButton:focus {
    background-color: #dbdbdb;
}

.manageListsModal-submitButton {
    margin: 3px 0 3px 8px;
    background-color: #4b8ec8;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 13px;
    border: none;
    padding: 7px 15px;
    cursor: pointer;
}

.manageListsModal-submitButton:hover,
.manageListsModal-submitButton:focus {
    background-color: #56aaee;
}

.manageListsModal-listDetailsRight {
    display: flex;
    align-items: stretch;
}

.manageListsModal-deleteIcon {
    height: 25px;
    width: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/trash-red.svg");
}

.manageListsModal-disabledDeleteIcon {
    height: 25px;
    width: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/trash-grey.svg");
}

.manageListsModal-inputField .fieldWrapper-error {
    margin-left: 0;
}

.manageListsModal-deleteConfirmationRight,
.manageListsModal-deleteConfirmationLeft {
    display: flex;
    align-items: center;
}

.manageListsModal-warningIcon {
    height: 35px;
    width: 35px;
    min-height: 35px;
    min-width: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../images/icons/warning.svg");
    margin-right: 8px;
}

.manageListsModal-deleteConfirmationSubMessage {
    font-size: .5em;
}

.manageListsModal-confirmDeleteButton {
    margin: 3px 0 3px 8px;
    background-color: #A42B3A;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 13px;
    border: none;
    padding: 7px 15px;
    cursor: pointer;
}

.manageListsModal-confirmDeleteButton:hover,
.manageListsModal-confirmDeleteButton:focus {
    background-color: #c23243;
}