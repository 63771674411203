.list {
    background-color: #FFF;
    border-radius: 20px;
    color: #000;
    margin: 30px 0 100px;
}
.tablet .list {
    max-width: 70vw;
    width: 70vw;
    margin: 30px auto 100px;
}

.list--spacing {
    margin-left: 15px;
    margin-right: 15px;
}

.list-header {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 30px;
    border-bottom: 1px solid #2B303A;
}

.tablet .list-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.list-closeButton {
    border: 1px solid #6f6f6f;
    background-color: #b9b9b9;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
}
.tablet .list-closeButton {
    width: 27px;
    height: 27px;
    top: -8px;
    right: -8px;
}
.list-closeButton:hover,
.list-closeButton:focus {
    background-color: #d7d7d7;
}

.list-closeIcon {
    width: 22px;
    height: 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../../images/icons/close.svg");
}
.tablet .list-closeIcon {
    width: 15px;
    height: 15px;
}

.list-sortedByValue .dropdown-button {
    padding-left: 0;
    padding-right: 0;
    font-weight: 600;
    font-size: .9em;
}

.list-sortedByValue {
    min-width: 100px;
}

.list-sortedByValue,
.list-ownedByValue {
    font-size: .9em;
    font-weight: 600;
}

.list-sortedByTitle,
.list-ownedByTitle {
    font-size: .75em;
    margin-right: .5em;
}

.list-name {
    font-size: 25px;
    padding: 0 10px;
}

.list-privateLabel {
    font-size: .85em;
    color: #707070;
    margin-left: 5px;
}

.list-selectList {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    order: 0;
}

.list-editListsLink {
    color: #00a4ee;
    font-size: .75em;
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 5px;
}

.list-referenceListMessage {
    font-size: .75em;
    color: #a7914f;
    margin-top: 8px;
    text-align: center;
}

.list-ownedBy {
    order: 1;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.list-sortedBy {
    order: 2;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.list-selectableListsMenu {
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
}

.list-dropdownButton {
    padding: 0 10px;
}
.tablet .list-ownedBy,
.tablet .list-sortedBy {
    flex-direction: column;
    align-items: flex-start;
    margin: 0
}

.tablet .list-selectList,
.tablet .list-ownedBy,
.tablet .list-sortedBy {
    order: 0;
}

.list-ownedByValueWrapper {
    display: flex;
    align-items: center;
}
.tablet .list-ownedByValueWrapper {
    display: block;
}

.list-followButton--follow {
    background-color: #009ee5;
    color: #fff;
    font-size: .75em;
    border: 1px solid #009ee5;
    cursor: pointer;
    padding: 3px 10px;
    margin-top: 3px;
    margin-left: 10px;
    border-radius: 3px;
}
.list-followButton--follow:focus,
.list-followButton--follow:hover {
    background-color: #00b4ff;
    border: 1px solid #00b4ff;
}
.tablet .list-followButton--follow {
    margin-left: 0;
}

.list-followButton--unfollow {
    background-color: #fff;
    color: #707070;
    font-size: .75em;
    border: 1px solid #707070;
    cursor: pointer;
    padding: 3px 10px;
    margin-top: 3px;
    margin-left: 10px;
    border-radius: 3px;
}
.list-followButton--unfollow:focus,
.list-followButton--unfollow:hover {
    background-color: #707070;
    color: #fff;
}
.tablet .list-followButton--unfollow {
    margin-left: 0;
}

.list-selectListButton {
    background: none;
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-selectListButton:focus,
.list-selectListButton:hover {
    color: #51a3ff;
}

.list-selectListButton:last-child {
    border-bottom: none;

}

.list-visibility {
    font-size: .8em;
}
