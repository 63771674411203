.hub {
    padding: 20px 15px 50px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.desktop .hub {
    padding: 30px 30px 50px;
    max-width: 70vw;
    width: 70vw;
    margin: 0 auto;
}

.hub-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.tablet .hub-header {
    flex-direction: row;
}

.hub-headerLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding-right: 20px;
}

.hub-header .hub-title {
    font: unset;
    font-weight: 600;
    font-size: 22px;
    color: #fff;
    border: 1px solid transparent;
}
.hub-header input.hub-title {
    margin: 0;
    padding: 0;
    border: 1px solid #575757;
}
.hub-header button.hub-title {
    cursor: text;
    background: none;
    border: 1px solid transparent;
    text-align: left;
    padding: 0;
    margin: 0;
    align-self: flex-start;
    position: relative;
}

.hub-header .hub-description {
    font: unset;
    font-size: .75em;
    color: #fff;
    border: 1px solid transparent;
}
.hub-header div.hub-description {
    margin-top: 4px;
}
.hub-header input.hub-description {
    margin: 0;
    padding: 0;
    border: 1px solid #575757;
}
.hub-header button.hub-description {
    cursor: text;
    background: none;
    border: 1px solid transparent;
    text-align: left;
    padding: 0;
    margin: 4px 0 0;
    align-self: flex-start;
    position: relative;
}

.hub-editIcon {
    width: 10px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../../images/icons/edit-square.svg");
    top: 0;
    right: -15px;
    position: absolute;
    cursor: pointer;
}

.hub-headerButtons {
    display: flex;
    align-items: center;
}

.hub-headerButton {
    background: none;
    border: 2px solid #fff;
    color: #fff;
    margin-left: 0;
    margin-top: 15px;
    border-radius: 5px;
    font-size: 12px;
    min-width: 80px;
    cursor: pointer;
    padding: 10px 7px;
    font-weight: 600;
}
.hub-headerButton:focus,
.hub-headerButton:hover {
    background-color: #525f6d;
}
.tablet .hub-headerButton {
    margin-left: 5px;
    margin-top: 0;
    align-self: center;
    padding: 7px;
}

.hub-hr {
    border: none;
    border-top: 3px solid #fff;
    margin: 10px 0;
}

.hub-tabs {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0;
    padding: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 3px solid #fff;
}
.desktop .hub-tabs {
    border-radius: 5px;
    margin: 15px 0 19px;
}

.hub-tab {
    list-style: none;
    cursor: pointer;
    flex: 1;
    font-weight: 600;
    padding: 12px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    color: #fff;
    border: none;
    margin: 0;
}
.hub-tab:focus,
.hub-tab:hover {
    background-color: #525f6d;
    outline: none;
}
.desktop .hub-tab {
    padding: 7px;
    margin: 0;
}

.hub-tab--selected {
    background-color: #fff;
    color: #2b303a;
}
.hub-tabs .hub-tab--selected:focus,
.hub-tabs .hub-tab--selected:hover {
    background-color: #fff;
}

.hub-tabText {
    display: none;
}
.desktop .hub-tabText {
    display: flex;
}

.hub-badge {
    background-color: #9A031E;
    color: #fff;
    padding: 3px;
    border-radius: 3px;
    font-size: .7em;
    min-width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.hub-icon {
    width: 20px;
    height: 1.6em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.desktop .hub-icon {
    margin-right: 10px;
    width: 15px;
    height: 1.1em;
}

button .hub-listIcon {
    background-image: url("../../../../../images/icons/list-light.svg");
}
.hub-tab--selected .hub-listIcon {
    background-image: url("../../../../../images/icons/list-dark.svg");
}

button .hub-messageIcon {
    width: 25px;
    height: 1.75em;
    margin-bottom: -3px;
    background-image: url("../../../../../images/icons/chat-bubble-light.svg");
}
.desktop button .hub-messageIcon {
    width: 20px;
    height: 1.3em;
}
.hub-tab--selected .hub-messageIcon {
    background-image: url("../../../../../images/icons/chat-bubble-dark.svg");
}

button .hub-settingsIcon {
    height: 1.7em;
    width: 20px;
    background-image: url("../../../../../images/icons/settings-light.svg");
}
.hub-tab--selected .hub-settingsIcon {
    background-image: url("../../../../../images/icons/settings-dark.svg");
}

.hub-tabSubtitle {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #2b303a;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 2px 0 5px;
    margin-bottom: 23px;
}
.desktop .hub-tabSubtitle {
    display: none;
}
