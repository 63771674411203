.spinner {
    position: relative;
}

.spinner.spinner-fullPage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(73, 73, 73, 0.65);
}

.spinnerComponent {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    height: 100px;
    width: 100px;
    animation-name: spin;
    animation-duration: 1.25s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-moz-keyframes spin {
    from { -moz-transform:rotate(0deg); }
    to { -moz-transform:rotate(359deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform:rotate(0deg); }
    to { -webkit-transform:rotate(359deg); }
}
@-ms-keyframes spin {
    from { -webkit-transform:rotate(0deg); }
    to { -webkit-transform:rotate(359deg); }
}
@-o-keyframes spin {
    from { -webkit-transform:rotate(0deg); }
    to { -webkit-transform:rotate(359deg); }
}
@keyframes spin {
    from { transform:rotate(0deg); }
    to { transform:rotate(359deg); }
}
