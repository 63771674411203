.listsView-summary {
    background-color: #000;
    border-radius: 10px;
    padding: 5px 10px 10px;
    color: #fff;
    margin: 10px 0 25px;
}

.listsView-summaryHeader {
    padding: 10px 10px 0 10px;
}

.listsView-summaryTitle {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 10px;
}

.listsView-summarySubTitle {
    font-size: .75em;
    text-align: center;
}

.listsView-summaryStats {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.listsView-summary .listsView-summaryStatusBar {
    padding: 10px;
    margin-top: 0;
}

.listsView-noListsMessage {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
}

.listsView-member {
    display: block;
    margin: 20px 0;
}
.desktop .listsView-member {
    margin: 15px 0;
}

.listsView-memberName {
    font-weight: 600;
    font-size: 22px;
}

.listsView-lists {
    display: flex;
    flex-direction: column;
}
.desktop .listsView-lists {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.listsView-list {
    display: inline-flex;
    margin: 10px 0;
    background-color: #fff;
    color: #1d2027;
    border: none;
    padding: 7px 0 0;
    border-radius: 10px;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}
.desktop .listsView-list:only-child {
    width: 100%;
}
.desktop .listsView-list {
    width: calc(35vw - 10px);
}

.listsView-listContent {
    padding: 0 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.listsView-listHeader {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.listsView-listHeaderLeft {
    display: flex;
    align-items: center;
}

.listsView-listName {
    font-weight: 600;
}

.listsView-giftCount {
    margin-left: 10px;
}

.listsView-defaultLabel {
    color: #707070;
    font-size: .85em;
    margin-left: .5em;
}
.listsView-list:only-child .listsView-defaultLabel {
    display: none;
}

.listsView-statusBarPlaceholder {
    height: 2em;
    display: flex;
    align-items: flex-end;
    font-size: .85em;
}

.listsView-statusBar {
    margin-top: 10px;
}

.listsView-statusBarWrapper {
    display: flex;
    height: 1em;
    border-radius: 10px;
    overflow: hidden;
}

.listsView-statusBarGreen {
    background-color: #2ecc71;
}

.listsView-statusBarYellow {
    background-color: #FFBD23;
}

.listsView-statusBarBlue {
    background-color: #0073ff;
}

.listsView-statusBarGray {
    background-color: #D6D6D6;
}

.listsView-statusBarLegend {
    display: flex;
    justify-content: space-around;
}

.listsView-statusBarLegendGroup {
    display: flex;
    align-items: center;
    font-size: .75em;
}

.listsView .listsView-statusBarTooltip {
    width: 140px;
    left: -55px;
    top: calc(125% - 3px);
    background-color: #292929;
}
.listsView .listsView-statusBarTooltip::after {
    left: 79px;
    border-color: transparent transparent #292929 transparent;
}

.listsView-statusBarGreenIcon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(-45deg);
    margin-right: 5px;
    background-image: url("../../../../../images/icons/tag-green.svg");
}

.listsView-statusBarYellowIcon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(-45deg);
    margin-right: 5px;
    background-image: url("../../../../../images/icons/tag-yellow.svg");
}

.listsView-statusBarBlueIcon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(-45deg);
    margin-right: 5px;
    background-image: url("../../../../../images/icons/tag-blue.svg");
}

.listsView-statusBarGrayIcon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(-45deg);
    margin-right: 5px;
    background-image: url("../../../../../images/icons/tag-grey.svg");
}

.listsView-listLink {
    color: #000;
    text-decoration: none;
    font-size: .8em;
    margin-top: 10px;
    font-weight: 600;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    background-color: #d6d6d6;
}
.listsView-listLink:focus,
.listsView-listLink:hover {
    background-color: #bababa;
}
